import call from '../../../../cetus-base/service'
import constants from './constants'
export default {
  namespaced: true,
  state: {
    banks: [],
    businessSources: [],
    customers: [],
    attachmentTypes: [],
    assetLocations: [],
    job: {},
    imageDialog: {
      open: false,
      type: 0
    }
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data
    }
  },
  getters: {
    setupGetters: (state) => (setup) => state[setup]
  },
  actions: {
    makeProclamation: ({ commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.proclamations, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        commit('MUTATE', { state: 'job', data: res.data.data[0] })
        Event.$emit('ApiSuccess', 'successfully added form details.')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    banks: async () => {
      const res = await call('get', constants.banks)
      return { data: res.data.data, state: 'banks' }
    },

    businessSources: async () => {
      const res = await call('get', constants.businessSources)
      return { data: res.data.data, state: 'businessSources' }
    },

    customers: async () => {
      const res = await call('get', constants.customers)
      return { data: res.data.data, state: 'customers' }
    },

    attachmentTypes: async () => {
      const res = await call('get', constants.attachmentTypes)
      return { data: res.data.data, state: 'attachmentTypes' }
    },

    getassetLocations: async () => {
      const res = await call('get', constants.assetLocations)
      return { data: res.data.data, state: 'assetLocations' }
    },

    getSetupData: ({ commit, dispatch }) => {
      commit('loader/SET_LOADING', true, { root: true })
      Promise.all([
        dispatch('banks'),
        dispatch('businessSources'),
        dispatch('customers'),
        dispatch('attachmentTypes'),
        dispatch('getassetLocations')
      ]).then((response) => {
        response.map((res) => {
          commit('MUTATE', { state: res.state, data: res.data })
        })
        commit('loader/SET_LOADING', false, { root: true })
      }).catch(() => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', 'failed to fetch setup data')
      })
    },

    attachingDocuments: ({ dispatch, commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.attachments, data).then((res) => {
        // Event.$emit('returnHome')
        Event.$emit('ApiSuccess', 'successfully added attachment.')
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    }
  }
}
