<template>
        <v-bottom-navigation
            :value="value"
            color="primary"
            grow
            fixed
            class="navigation"
        >

            <v-btn link to="/dashboard">
                <span>Dashboard</span>
                <v-icon>dashboard</v-icon>
            </v-btn>

            <v-divider vertical inset></v-divider>

            <v-btn link :to="{name: 'StorageCard'}">
                <span>Storage</span>
                <v-icon>directions_car</v-icon>
            </v-btn>

            <v-divider vertical inset></v-divider>

            <v-btn link target="_blank" to="/proclamation">
                <span>Proclamation</span>
                <v-icon>backup_table</v-icon>
            </v-btn>
        </v-bottom-navigation>
</template>
<style>
  .navigation.v-item-group.v-bottom-navigation {
        bottom: 0;
        display: flex;
        left: 35%;
        justify-content: center;
        width: 30%;
        box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    }

  @media only screen and (max-width: 768px) {
        .navigation.v-item-group.v-bottom-navigation {
            bottom: 0;
            display: flex;
            left: 0;
            justify-content: center;
            width: 100%;
            box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
        }
    }
</style>
<script>
export default {
  name: 'BottomNavigation',
  data: function () {
    return {
      value: 0
    }
  }
}
</script>
