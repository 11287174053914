<template>
  <v-container>
    <v-form
      ref="procform"
      v-model="isValid"
      @submit.prevent="make_proclamation"
    >
      <v-card class="my-2 cardStyle" flat>
        <v-card-title>Proclamation Form</v-card-title>
        <v-card-text>
          <v-select
            :rules="rules"
            dense
            v-model="formData.document_type"
            prepend-inner-icon="assignment"
            label="Document Type"
            clearable
            filled
            chips
            :items="items"
          ></v-select>
          <v-text-field
            :rules="rules"
            dense
            v-model="formData.customer_first_name"
            prepend-inner-icon="person_outline"
            clearable
            filled
            label="Customer First Name"
          ></v-text-field>
          <v-text-field
            :rules="rules"
            dense
            v-model="formData.customer_second_name"
            prepend-inner-icon="person_outline"
            clearable
            filled
            label="Customer Second Name"
          ></v-text-field>
          <v-text-field
            :rules="rules"
            dense
            v-model="formData.customer_last_name"
            prepend-inner-icon="person_outline"
            clearable
            filled
            label="Customer Last Name"
          ></v-text-field>
           <v-select
            :rules="formData.document_type === 'Repossesion Expense' ? rules : []"
            dense
            v-show="formData.document_type === 'Repossesion Expense'"
            v-model="formData.business_source_code"
            prepend-inner-icon="business"
            label="Business Source Code"
            item-text="business_code_name"
            item-value="business_code"
            @change="selectBusiness"
            clearable
            filled
            chips
            :items="businessSources"
          ></v-select>
          <v-chip v-show="false" class="mb-2" label style="font-size: 17px;">{{ formData.business_source_name }}</v-chip>
          <v-select
            :rules="formData.document_type === 'Proclamation Notice' ? rules : []"
            dense
            v-show="formData.document_type === 'Proclamation Notice'"
            v-model="formData.customer_no"
            prepend-inner-icon="people"
            label="Customer"
            item-text="customer_name"
            item-value="customer_no"
            @change="selectCustomer"
            clearable
            filled
            chips
            :items="customers"
          ></v-select>
          <v-chip v-show="false" class="mb-2" label style="font-size: 17px;">{{ formData.customer_name }}</v-chip>
          <v-select
            :rules="rules"
            dense
            v-model="formData.location"
            prepend-inner-icon="place"
            label="Location"
            item-text="description"
            item-value="code"
            clearable
            filled
            chips
            :items="assetLocations"
          ></v-select>
          <v-text-field
            :rules="rules"
            dense
            type="number"
            v-model="formData.amount_to_be_collected"
            prepend-inner-icon="credit_card"
            clearable
            filled
            label="Amount To Be Collected"
          ></v-text-field>
          <v-text-field
            :rules="rules"
            dense
            v-model="formData.vehicle_registration_no"
            prepend-inner-icon="directions_car"
            clearable
            filled
            label="Vehicle Registration"
          ></v-text-field>
          <v-textarea
            v-model="formData.comments"
            dense
            clearable
            prepend-inner-icon="comment"
            filled
            rows="2"
            label="Enter Comments"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" type="submit">
            <v-icon class="mx-3">cloud_upload</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
      <div style="margin-top: 15%"></div>
    </v-form>
    <ImageModal :job="job" />
  </v-container>
</template>
<script>
import ProclamationMethods from '../ProclamationMethods'
import ImageModal from '../components/ImageModal'
export default {
  name: 'ProcForm',
  components: { ImageModal },
  data: function () {
    return {
      items: ['Repossesion Expense', 'Proclamation Notice'],
      formData: {
        document_type: '',
        customer_first_name: '',
        customer_second_name: '',
        customer_last_name: '',
        vehicle_registration_no: '',
        business_source_code: '',
        business_source_name: '',
        amount_to_be_collected: 0,
        comments: '',
        location: '',
        // bank_code: '',
        customer_no: '',
        customer_name: ''
      },
      isValid: false,
      rules: [(v) => !!v || 'Field is required']
    }
  },
  watch: {
    job () {
      if (Object.keys(this.job).length !== 0) {
        this.imageDialog = true
      }
    }
  },
  computed: {
    // banks: function () {
    //   return this.$store.getters['proclamation/setupGetters']('banks')
    // },
    assetLocations: function () {
      return this.$store.getters['proclamation/setupGetters']('assetLocations')
    },
    imageDialog: {
      get () {
        return this.$store.getters['proclamation/setupGetters']('imageDialog').open
      },
      set (value) {
        this.$store.commit('proclamation/MUTATE', { state: 'imageDialog', data: { open: value, type: 0 } })
      }
    },
    job: function () {
      return this.$store.getters['proclamation/setupGetters']('job')
    },
    businessSources: function () {
      return this.$store.getters['proclamation/setupGetters'](
        'businessSources'
      )
    },
    customers: function () {
      return this.$store.getters['proclamation/setupGetters']('customers')
    }
  },
  methods: {
    ...ProclamationMethods
  }
}
</script>
