import constants from './constants'
import call from '../../service/'

export default {
  namespaced: true,
  state: {
    creds: {
      token: '',
      user: ''
    }
  },
  mutations: {
    SET_AUTH: (state, logins) => {
      for (const key in state.creds) {
        state.creds[key] = logins[key]
      }
    },
    UNSET_AUTH: state => {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in state.creds) {
        state.creds[key] = ''
      }
    }
  },
  getters: {
    token: state => state.creds.token,
    user: state => state.creds.user
  },
  actions: {
    login: (context, data) => {
      context.commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.login, data).then(res => {
        context.commit('SET_AUTH', res.data.data)
        context.commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiSuccess', 'logging in ...')
        setTimeout(() => {
          Event.$emit('login')
        }, 2000)
      }).catch(() => {
        Event.$emit('ApiError', 'Sorry, wrong credentials')
        context.commit('loader/SET_LOADING', false, { root: true })
      })
    },
    sendLink: (context, data) => {
      context.commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.sendLink, data).then((res) => {
        Event.$emit('ApiSuccess', res.data.message + ' Check email to reset your password')
        context.commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        Event.$emit('ApiError', error.response.data.message)
        context.commit('loader/SET_LOADING', false, { root: true })
      })
    },
    requestCredentials: (context, data) => {
      context.commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.requestCredentials, data).then(res => {
        res.data === null
          ? Event.$emit('ApiWarning', res.data.message)
          : Event.$emit('ApiSuccess', res.data.message + ' Check your email to set your password')
        context.commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        Event.$emit('ApiError', error.response.data.message)
        context.commit('loader/SET_LOADING', false, { root: true })
      })
    },
    setPassword: (context, data) => {
      context.commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.setPassword, data).then((res) => {
        Event.$emit('ApiSuccess', res.data.message)
        context.commit('SET_AUTH', res.data.data)
        context.commit('loader/SET_LOADING', false, { root: true })
        setTimeout(() => {
          Event.$emit('login')
        }, 1000)
      }).catch(() => {
        Event.$emit('ApiError', 'User account not set')
        context.commit('loader/SET_LOADING', false, { root: true })
      })
    },
    logout: context => {
      call('post', constants.logout).then(() => {
        context.commit('UNSET_AUTH')
        Event.$emit('logout')
      })
    }
  }
}
