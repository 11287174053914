<template>
  <div>
    <v-dialog v-model="imageDialog.open" persistent max-width="320">
      <v-card>
        <v-card-title class="headline"> {{ imageDialog.type === 1 ? `Edit Photo: ${ imageDialog.attachment.line_no }` : 'Add Attachment' }} </v-card-title>
        <v-card-text>
          <v-form v-model="isValid" ref="imageForm">
            <v-select
              clearable
              filled
              chips
              label="Attachment Types"
              :items="attachmentTypes"
              item-value="description"
              item-text="description"
              v-model="formData.attachmentType"
              :rules="tagRules"
              :hint="`Enter Photos i.e ${attachmentTypes.map((item) => item.description)}`"
              persistent-hint
            ></v-select>
            <v-text-field label="Enter Description" clearable filled v-model="formData.description" :rules="tagRules"></v-text-field>
            <v-btn
              outlined
              block
              class="btnActions mt-3"
              color="primary"
              @click="onButtonClick"
            >
              <v-icon class="mx-3">mdi-image-plus</v-icon>
              Add/Take Attachment
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div></div>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            class="btnActions"
            @click="imageDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      type="file"
      ref="uploader"
      class="d-none"
      accept="image/*"
      style="visibility: hidden"
      @change="upload(job.no, photoTags)"
    />
  </div>
</template>
<script>
import ProclamationMethods from '../ProclamationMethods'
export default {
  name: 'ImageModal',
  props: { job: Object, documentType: String },
  data: function () {
    return {
      isValid: false,
      photoTags: '',
      tagRules: [
        v => !!v || 'Field is required'
      ],
      formData: {
        comments: '',
        attachmentType: null,
        line_no: 0,
        update_code: 0,
        description: ''
      }
    }
  },
  watch: {
    imageDialog () {
      if (this.imageDialog.attachment !== undefined) {
        this.formData.attachmentType = this.imageDialog.attachment.attachment_type
        this.formData.line_no = this.imageDialog.attachment.line_no
        this.formData.update_code = this.imageDialog.type
      }
    }
  },
  computed: {
    attachmentTypes: function () {
      return this.$store.getters['proclamation/setupGetters']('attachmentTypes')
    },
    imageDialog: {
      get () {
        return this.$store.getters['proclamation/setupGetters']('imageDialog')
      },
      set (value) {
        this.$store.commit('proclamation/MUTATE', {
          state: 'imageDialog',
          data: { open: value, type: 0 }
        })
      }
    }
  },
  methods: {
    ...ProclamationMethods
  }
}
</script>
