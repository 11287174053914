import call from '../../../../cetus-base/service/'
import constants from './constants'
import RepoClass from './RepoClass'

export default {
  namespaced: true,
  state: {
    jobs: [],
    imageDialog: {
      open: false,
      type: 0
    },
    agents: [],
    attachmentTypes: [],
    location: {},
    job: {},
    searchDialog: false,
    prevRoute: '',
    ServiceCharges: []
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data
    }
  },
  getters: {
    getJobsStats: (state) => RepoClass.getJobsStats(state),
    getJobByStatus: (state) => (status) => RepoClass.getJobByStatus(state, status),
    getJobData: (state) => (jobCode) => RepoClass.getJobData(state, jobCode),
    getJobObject: (state) => () => RepoClass.getJobObject(state),
    dashboardGetter: (state) => (param) => state[param],
    getNotes: () => (attachments) => RepoClass.getNotes(attachments),
    getPhotos: () => (attachments, type) => RepoClass.getPhotos(attachments, type),
    getAgents: (state) => (dataObject) => RepoClass.getAgents(state, dataObject),
    getAgentAuth: (state) => (agentCode) => RepoClass.getAgentAuth(state, agentCode),
    getSearchDialog: (state) => state.searchDialog
  },
  actions: {
    setSearchDialog: ({ commit }, data) => {
      commit('MUTATE', { state: 'searchDialog', data })
    },
    updatePrevRoute: ({ commit }, data) => {
      commit('MUTATE', { state: 'prevRoute', data })
    },
    startJobs: ({ dispatch, commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.start_jobs, data).then((res) => {
        Event.$emit('ApiSuccess', 'Job card Accepted. Refreshing ...')
        dispatch('getJobCard', data.no)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    finishJobs: ({ dispatch, commit }, data) => {
      delete data.name
      delete data.statusColor
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.finish_jobs, data).then((res) => {
        Event.$emit('ApiSuccess', 'Job card Finished. Refreshing ...')
        dispatch('getJobCard', data.no)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    submitJobs: ({ dispatch, commit }, data) => {
      delete data.name
      delete data.statusColor
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.submit_jobs, data).then((res) => {
        Event.$emit('ApiSuccess', 'Job card submitted. Refreshing ...')
        dispatch('getJobCard', data.no)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    attachingDocuments: ({ dispatch, commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.attachments, data).then((res) => {
        Event.$emit('ApiSuccess', 'Photo/Image Has Been attached. Refreshing ...')
        dispatch('getJobCard', data.code)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    postNotes: ({ dispatch, commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.notes, data).then((res) => {
        Event.$emit('ApiSuccess', 'Successfully submitted notes. Refreshing ...')
        Event.$emit('returnCard', data.document_no)
        dispatch('getJobCard', data.document_no)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    removeAttachments: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.removeAttachments, data).then((res) => {
        if (data.type === 'Attachment') {
          Event.$emit('returnCard', data.document_no)
        }
        dispatch('getJobCard', data.document_no)
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    reassignJobCard: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.reassignJobCard, data).then((res) => {
        Event.$emit('ApiSuccess', data.type === 0
          ? 'Job card successfully reassigned. redirecting to jobs ...'
          : 'Job card successfully Recalled. redirecting to jobs ...')
        Event.$emit('returnMain')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    getLocation: ({ commit }, code) => {
      call('post', constants.getLocation, { code }).then((res) => {
        commit('MUTATE', { state: 'location', data: res.data.data[0] })
      }).catch(() => {
        Event.$emit('ApiError', 'failed to fetch location')
      })
    },

    attachmentTypes: async () => {
      const res = await call('get', constants.attachmentTypes)
      return { data: res.data.data, state: 'attachmentTypes' }
    },

    GetJobs: async () => {
      const res = await call('get', constants.jobs)
      return { data: res.data.data, state: 'jobs' }
    },

    getCoordinatorJobs: async () => {
      const res = await call('get', constants.getCoordinatorCards)
      return { data: res.data.data, state: 'jobs' }
    },

    getJobData: ({ commit, dispatch }, userData) => {
      commit('loader/SET_LOADING', true, { root: true })
      let isCoordinator = false
      call('get', constants.agents).then((res) => {
        isCoordinator = res.data.data.filter((agent) => userData.agent_code === agent.code)[0].is_coordinator
        commit('MUTATE', { state: 'agents', data: res.data.data })
        Promise.all([
          isCoordinator ? dispatch('getCoordinatorJobs') : dispatch('GetJobs'),
          dispatch('attachmentTypes')
        ]).then((response) => {
          response.map((res) => {
            commit('MUTATE', { state: res.state, data: res.data })
          })
          commit('loader/SET_LOADING', false, { root: true })
        }).catch((error) => {
          commit('loader/SET_LOADING', false, { root: true })
          Event.$emit('ApiError', error.response.data.message)
        })
      }).catch((error) => {
        console.log('error response', error)
        // Event.$emit('ApiError', error.response.data.message)
      })
    },

    getJobCard: ({ commit, dispatch }, documentNo) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.getJobCard(documentNo)).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        commit('MUTATE', { state: 'job', data: res.data.data[0] })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    getServiceCharges: ({ commit, dispatch }) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.ServiceCharges).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        commit('MUTATE', { state: 'ServiceCharges', data: res.data.data })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    addRepossessionLines: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.AddServiceCharges, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiSuccess', 'Success: Added request ... refreshing')
        dispatch('getJobCard', data.documentNo)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },

    deleteRepoLine: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.deleteRepoLine, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiSuccess', 'Success: Deleted item')
        dispatch('getJobCard', data.documentNo)
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    }
  }
}
