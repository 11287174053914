<template>
  <div
    class="file--attachment d-flex flex-column"
    :style="{
      border: `${Outlined ? '3' : '0'}px dashed ${
        fileOptions[current].borderColor
      }`,
      background: Outlined ? 'rgb(227 242 253 / 45%)' : '',
    }"
  >
    <div class="align-self-center">
      <div
        v-if="!showFile"
        class="font-weight-bold d-flex flex-column"
      >
        <div
          v-ripple="{ center: true }"
          class="file--avatar--outline align-self-center"
        >
          <!-- uploader -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-avatar
                    :color="`${fileOptions[current].color} lighten-3`"
                    :size="AvatarSize"
                    class="file--avatar"
                    @click="$refs.uploader.click()"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon
                    :color="`${fileOptions[current].color} darken-4`"
                    :size="Math.round((8 / 11) * AvatarSize)"
                    >
                    {{ Icon }}
                    </v-icon>
                </v-avatar>
            </template>
            <span>Update/Add File attachment</span>
          </v-tooltip>
        </div>
        <div>
           {{ uploadedFile.name !== undefined ? uploadedFile.name : 'Add '+`${IsImage ? "Profile Picture" : "Checklist attachment"}` }}
        </div>
      </div>
    </div>
    <input
      ref="uploader"
      type="file"
      class="d-none"
      :accept="Accept"
      style="visibility: hidden"
      @change="upload()"
    />
  </div>
</template>
<script>
import helpers from '../../../cetus-base/utilities/helpers/helper.js'
export default {
  name: 'FileUpload',
  props: {
    Outlined: Boolean,
    Icon: String,
    Accept: {
      default: 'image/jpeg,image/png,application/pdf',
      type: String
    },
    IsImage: Boolean,
    showFile: {
      default: false,
      type: Boolean
    },
    defaultFile: {
      default:
        'https://www.icmetl.org/wp-content/uploads/2020/11/user-icon-human-person-sign-vector-10206693.png',
      type: String
    },
    base64File: {
      default: '',
      type: String
    },
    AvatarSize: {
      default: 110,
      type: Number
    },
    FileName: {
      default: 'file-download',
      type: String
    },
    status: {
      default: 'default',
      type: String
    },
    displayOptions: {
      default: () => {
        return {
          elevation: '0',
          width: '60ch',
          isEdit: false
        }
      },
      type: Object
    }
  },
  data: function () {
    return {
      uploadedFile: {},
      current: 'default'
    }
  },
  computed: {
    fileOptions () {
      return {
        default: {
          borderColor: '#1ABC9C',
          color: 'teal'
        },
        error: {
          borderColor: 'red',
          color: 'deep-orange'
        },
        success: {
          borderColor: 'green',
          color: 'green'
        }
      }
    }
  },
  mounted () {
    Event.$on('clearUpload', () => {
      this.uploadedFile = {}
      this.current = 'default'
    })
  },
  watch: {
    status () {
      this.current = this.status
    }
  },
  methods: {
    upload () {
      const file = this.$refs.uploader.files[0]
      this.uploadedFile = file
      this.$emit('updateFile', { file })
    },
    getMimeType (base64) {
      return helpers.detectMimeType(base64)
    },
    getFileExtension (base64) {
      return helpers.fileExtension(base64)
    },
    updateFile () {
      this.uploadedFile = {}
      this.current = 'default'
      this.$emit('updateFile', { file: this.uploadedFile })
    }
  }
}
</script>
<style>
.file--attachment {
  border-radius: 4px;
  padding: 1% 0 1% 0;
}
.file--avatar {
  cursor: pointer;
}
.file--avatar--outline {
  padding: 7.5px;
  border-radius: 100%;
  background-color: #e8eaf6;
}
</style>
