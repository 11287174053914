export default {
  storage: 'capricorn/storage',
  storageCard: (id) => `capricorn/storage?no=${id}`,
  updateStatus: (param) => `capricorn/storage/updateStatus?${buildQuery(param)}`,
  storageCharges: 'capricorn/storage/storageCharges',
  valuation: id => `capricorn/storage/valuation/${id}`,
  update: 'capricorn/storage/update',
  addCharges: 'capricorn/storage/lines',
  book: 'capricorn/storage/book',
  checkout: 'capricorn/storage/checkout',
  close: 'capricorn/storage/close',
  yards: 'capricorn/storage/yards'
}

function buildQuery (param) {
  const query = new URLSearchParams(param)
  return query.toString()
}
