<template>
  <v-container class="mb-10 pb-10">
    <v-card flat class="mx-auto cardStyle">
      <v-card-title>Accrued Charges</v-card-title>
      <v-card-text>
        <div
          class="
            d-flex
            justify-center
            text-h4
            cyan--text
            font-weight-bold
            my-2
          "
        >
        <span v-if="parseInt(storageCard.amount) === 0">{{ storageCard.amount | currency }}</span>
        <span v-else>{{ shortNumberPipe(storageCard.amount) }}</span>
        </div>
        <div>Storage Charge Items</div>
        <v-text-field
            outlined
            v-model="search"
            dense
            placeholder="Search Charges"
          >
            <template v-slot:prepend-inner>
              <v-icon size="25" class="mb-2">search</v-icon>
            </template>
          </v-text-field>
        <v-list dense class="mt-n3">
          <v-chip large label v-for="(item, i) in storageLines" :key="i" style="width: 100%" outlined class="my-2" color="primary">
            <v-list-item
            dense
            :key="i"
            class="my-2"
          >
            <v-list-item-action class="ml-n2">
              <v-icon color="primary">check_circle_outline</v-icon></v-list-item-action
            >
            <v-list-item-content class="ml-n4">
              <v-list-item-title class="teal--text">{{ item.storageCode }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              {{ item.amount | currency }}
            </v-list-item-action>
          </v-list-item>
          </v-chip>
        </v-list>
      </v-card-text>
      <AddCharges v-if="storageCard.status === 'Booked'" :storageCard="storageCard" />
    </v-card>
  </v-container>
</template>
<script>
import AddCharges from './AddCharges.vue'
import helpers from '../../../cetus-base/utilities/helpers/helper.js'
export default {
  name: 'StorageCharges',
  components: { AddCharges },
  data: function () {
    return {
      search: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.$store.dispatch('storage/getStorageCard', to.params.no)
      v.$store.dispatch('storage/storageCharges')
    })
  },
  computed: {
    storageCard: function () {
      return this.$store.getters['storage/storageGetters']('storageCard')
    },
    storageLines: function () {
      return this.search !== '' ? this.$store.getters['storage/storageGetters']('storageCard').storageLines.filter(
        (item, i) =>
          Object.keys(item).filter(
            (key) =>
              item[key]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
          ).length !== 0
      ) : this.$store.getters['storage/storageGetters']('storageCard').storageLines
    }
  },
  methods: {
    shortNumberPipe: function (val) {
      return helpers.shortNumberPipe(val)
    }
  }
}
</script>
