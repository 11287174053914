import Dashboard from './views/DashboardLayout.vue'

export default [
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        redirect: '/dashboard/main'
      }
    ]
  }
]
