<template>
  <v-container class="mb-10 pb-10">
    <v-card flat class="mx-auto cardStyle">
      <v-card-title>Requested Amount</v-card-title>
      <v-card-text>
        <div
            class="
            d-flex
            justify-center
            text-h4
            cyan--text
            font-weight-bold
            my-2
          "
        >
          <span v-if="parseInt(job.amount) === 0">{{ totalAmount | currency }}</span>
          <span v-else>{{ shortNumberPipe(totalAmount) }}</span>
        </div>
        <div>Requested Service Charges</div>
        <v-text-field
            outlined
            v-model="search"
            dense
            placeholder="Search Charges"
        >
          <template v-slot:prepend-inner>
            <v-icon size="25" class="mb-2">search</v-icon>
          </template>
        </v-text-field>
        <v-list two-line class="mt-n3">
            <v-list-item
                dense
                v-for="(item, i) in reposessionLines"
                :key="i"
                style="border: 1px solid #0A9971; border-radius: 4px;"
                class="my-2"
            >
              <v-list-item-action class="ml-n2">
                <v-icon color="primary">check_circle_outline</v-icon></v-list-item-action>
              <v-list-item-content class="ml-n4">
                <v-list-item-title class="teal--text">
                  {{ item.accountNo }}
                  <v-chip small :color="getStatusColor(item.paymentStatus)" class="text-white">{{ item.paymentStatus }}</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{
                      item.description
                    }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <div class="d-flex justify-end">
                  {{ item.amount | currency }}
              </div>
              <v-tooltip bottom v-if="item.paymentStatus === 'New'">
                <template v-slot:activator="{ on, attrs}">
                  <v-list-item-action right v-on="on" v-bind="attrs">
                    <v-btn icon color="red darken-1" @click="deleteItem(item)" ><v-icon>delete</v-icon></v-btn>
                  </v-list-item-action>
                </template>
                <span>Delete Item</span>
              </v-tooltip>
            </v-list-item>
        </v-list>
      </v-card-text>
      <AddServiceCharge v-if="job.document_status === 'In Progress'" :job="job" />
    </v-card>
  </v-container>
</template>
<script>
import AddServiceCharge from './AddServiceCharge.vue'
import { helper } from '@/packages/cetus-base/utilities'

export default {
  name: 'RequestCash',
  components: { AddServiceCharge },
  data: function () {
    return {
      search: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      const { jobCode } = v.$route.params
      v.getJob(atob(jobCode))
      v.$store.dispatch('reposession/getServiceCharges')
      v.$store.dispatch('reposession/updatePrevRoute', from)
    })
  },
  computed: {
    totalAmount: function () {
      return this.reposessionLines.reduce((a, b) => a + parseInt(b.amount), 0)
    },
    job: function () {
      return this.$store.getters['reposession/getJobObject']()
    },
    reposessionLines: function () {
      return this.search !== '' ? this.job.repossessionLines.filter(
        (item, i) =>
          Object.keys(item).filter(
            (key) =>
              item[key]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
          ).length !== 0
      ) : this.job.repossessionLines
    }
  },
  methods: {
    shortNumberPipe: function (val) {
      return helper.shortNumberPipe(val)
    },
    getJob (documentNo) {
      this.$store.dispatch('reposession/getJobCard', documentNo)
    },
    getLocation: function (code) {
      this.$store.dispatch('reposession/getLocation', code)
    },
    deleteItem: function (item) {
      this.$store.dispatch('reposession/deleteRepoLine', item)
    },
    getStatusColor: function (status) {
      return helper.getStatusColor(status)
    }
  }
}
</script>
