<template>
  <div class="mt-n4">
    <v-dialog v-model="AddChargesDialog" content-class="modal--scanner">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex justify-center">
          <v-btn
              color="primary"
              dark
              class="text-capitalize mb-12 add--charges"
              v-on="on"
              v-bind="attrs"
          >
            <v-icon class="mx-3">fact_check</v-icon>
            Add Service Charges
          </v-btn>
        </div>
      </template>
      <v-card class="mx-auto">
        <div class="text-md-body-1 py-2 mx-3 font-weight-bold d-flex justify-space-between">
          <div class="pt-2">Add Service Charges</div>
          <v-btn icon @click="AddChargesDialog = false" color="red">
            <v-icon size="30">close</v-icon>
          </v-btn>
        </div>
        <div class="mx-3">
          <v-form v-model="isValid" ref="serviceChargeForm">
            <v-autocomplete
              :items="charges"
              :item-text="(item) => `${ item.serviceCode } - ${ item.description }`"
              :item-value="(item) => item.serviceCode"
              filled
              dense
              :rules="rules"
              v-model="formData.accountNo"
              label="Select Service Charge"
            ></v-autocomplete>
            <v-text-field filled v-model="formData.amount" :rules="rules" type="number" min="1" label="Enter Amount" dense></v-text-field>
            <v-textarea v-model="formData.description" filled dense rows="3" label="Description"></v-textarea>
          </v-form>
        </div>
        <v-card-actions>
          <v-btn :disabled="formData.amount === 0" @click="addServiceCharge" block bottom elevation="0" color="cyan darken-3 white--text">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'AddServiceCharges',
  props: { job: Object },
  data: function () {
    return {
      AddChargesDialog: false,
      isValid: true,
      formData: {
        accountNo: '',
        amount: 1,
        description: ''
      },
      rules: [(v) => !!v || 'Field is required']
    }
  },
  mounted () {},
  computed: {
    newAmount: {
      get (val) {
        return 100
      },
      set (val) {
        console.log(val)
      }
    },
    charges: function () {
      return this.$store.getters['reposession/dashboardGetter']('ServiceCharges')
    }
  },
  methods: {
    addServiceCharge: function () {
      const { accountNo, amount, description } = this.formData
      this.$store.dispatch('reposession/addRepossessionLines', { accountNo, amount: parseInt(amount), description, documentNo: this.job.no, accountType: 'Service Charge' })
      this.AddChargesDialog = false
    }
  },
  watch: {
    'formData.accountNo': {
      handler (n, o) {
        this.formData.description = this.charges.filter((el) => el.serviceCode === n)[0].description
        this.formData.amount = this.charges.filter((el) => el.serviceCode === n)[0].defaultAmount
      }
    }
  }
}
</script>
<style>
@import url("../styles.css");
</style>
