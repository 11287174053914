export default {
  jobs: 'capricorn/repossession/job',
  start_jobs: 'capricorn/repossession/job/start',
  finish_jobs: 'capricorn/repossession/job/finish',
  submit_jobs: 'capricorn/repossession/job/submit',
  attachments: 'capricorn/repossession/job/attachment',
  notes: 'capricorn/repossession/job/notes',
  removeAttachments: 'capricorn/repossession/attachment/destroy',
  reassignJobCard: 'capricorn/repossession/job/reassignJobCard',
  agents: 'capricorn/auth/agents',
  attachmentTypes: 'capricorn/setup/attachmentTypes',
  getLocation: 'capricorn/setup/assetLocationsByFilter',
  getJobCard: id => `capricorn/repossession/job/getJobCard/${id}`,
  getCoordinatorCards: 'capricorn/repossession/job/coordinatorJobs',
  ServiceCharges: 'capricorn/repossession/job/ServiceCharges',
  AddServiceCharges: 'capricorn/repossession/job/AddServiceCharges',
  deleteRepoLine: 'capricorn/repossession/job/deleteRepoLine'
}
