import { DashboardRoutes, DashboardStore } from './modules/dashboard'
import { AuthRoutes, AuthStore } from './modules/auth'

export default {
  install (Vue, options) {
    if (options.router) {
      options.router.addRoute(...DashboardRoutes)
      options.router.addRoute(...AuthRoutes)
    }
    if (options.store) {
      options.store.registerModule('Dashboard', DashboardStore)
      options.store.registerModule('Auth', AuthStore)
    }
  }
}
