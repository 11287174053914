import { RepoRoutes, RepoStore } from './src/modules/repossession'
import { ProRoutes, ProStore } from './src/modules/Proclamation'

export default {
  install (Vue, options) {
    options.router.addRoute(...RepoRoutes)
    options.router.addRoute(...ProRoutes)
    /** Registering store modules */
    options.store.registerModule('reposession', RepoStore)
    options.store.registerModule('proclamation', ProStore)
  }
}
