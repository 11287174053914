import store from './store'

class RepoClass {
  constructor () {
    this.$store = store
    this.getStatusColor = (status) => {
      let color = ''
      switch (status) {
        case 'Released': color = 'blue lighten-1'; break
        case 'In Progress': color = 'orange lighten-1'; break
        case 'Finished': color = 'green lighten-1'; break
        case 'Canceled': color = 'deep-orange lighten-1'; break
        case 'Submitted': color = 'teal lighten-1'; break
        default:
          color = 'blue lighten-1'
          break
      }
      return color
    }
  }

  getJobsStats (state) {
    return [
      {
        count: state.jobs.filter((job) => job.document_status === 'Released').length,
        status: 'Released',
        avatarColor: 'blue lighten-4',
        iconColor: 'blue darken-4',
        icon: 'view_agenda',
        job: state.jobs.filter((job) => job.document_status === 'Released')
      },
      {
        count: state.jobs.filter((job) => job.document_status === 'In Progress').length,
        status: 'In Progress',
        avatarColor: 'orange lighten-4',
        iconColor: 'orange darken-4',
        icon: 'assignment',
        job: state.jobs.filter((job) => job.document_status === 'In Progress')
      },
      {
        count: state.jobs.filter((job) => job.document_status === 'Submitted').length,
        status: 'Submitted',
        avatarColor: 'teal lighten-4',
        iconColor: 'teal darken-4',
        icon: 'fact_check',
        job: state.jobs.filter((job) => job.document_status === 'Submitted')
      },
      {
        count: state.jobs.filter((job) => job.document_status === 'Finished').length,
        status: 'Finished',
        avatarColor: 'green lighten-4',
        iconColor: 'green darken-4',
        icon: 'assignment_turned_in',
        job: state.jobs.filter((job) => job.document_status === 'Finished')
      },
      {
        count: state.jobs.filter((job) => job.document_status === 'Canceled').length,
        status: 'Canceled',
        avatarColor: 'deep-orange lighten-4',
        iconColor: 'deep-orange darken-4',
        icon: 'cancel_presentation',
        job: state.jobs.filter((job) => job.document_status === 'Canceled')
      },
      {
        count: state.jobs.length,
        status: 'All',
        avatarColor: 'cyan lighten-4',
        iconColor: 'cyan darken-4',
        icon: 'dashboard',
        job: state.jobs
      }
    ]
  }

  getJobByStatus (state, status) {
    if (status === 'All') {
      return state.jobs.map((job) => {
        job.name = `${job.customer_first_name} ${job.customer_second_name} ${job.customer_last_name}`
        job.statusColor = this.getStatusColor(job.document_status)
        return job
      }).sort((a, b) => new Date(b.date_received) - new Date(a.date_received))
    } else {
      return state.jobs.filter((job) => {
        job.name = `${job.customer_first_name} ${job.customer_second_name} ${job.customer_last_name}`
        job.statusColor = this.getStatusColor(job.document_status)
        return job.document_status === status
      }).sort((a, b) => new Date(b.date_received) - new Date(a.date_received))
    }
  }

  getJobData (state, jobCode) {
    const job = state.jobs.filter((job) => {
      job.name = `${job.customer_first_name} ${job.customer_second_name} ${job.customer_last_name}`
      job.statusColor = this.getStatusColor(job.document_status)
      return job.no === jobCode
    })
    return job[0]
  }

  getJobObject (state) {
    const job = state.job
    job.name = `${job.customer_first_name} ${job.customer_second_name} ${job.customer_last_name}`
    job.statusColor = this.getStatusColor(job.document_status)
    return job
  }

  getNotes (attachments) {
    if (attachments !== undefined) {
      return attachments.filter((attach) => attach.type === 'Note')
    } else {
      return []
    }
  }

  getPhotos (attachments, type) {
    if (attachments !== undefined) {
      return type === 'All'
        ? attachments.filter((attach) => attach.type === 'Attachment')
        : attachments.filter((attach) => attach.type === 'Attachment' && attach.attachment_type === type)
    } else {
      return []
    }
  }

  getAgents (state, { job, coordinator }) {
    if (state.jobs.length !== 0 && state.agents.length !== 0) {
      return state.agents.filter((agent) =>
        agent.code !== job.agent_code && agent.assigned_coordinator === coordinator.agent_code)
    } else {
      return []
    }
  }

  getAgentAuth (state, agentCode) {
    if (state.agents.length !== 0) {
      return state.agents.filter((agent) => agent.code === agentCode)[0]
    } else {
      return []
    }
  }
}

export default new RepoClass()
