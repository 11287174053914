import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './packages/cetus-base/store'
import { filters } from './packages/cetus-base/utilities'
import { listener, status } from './packages/cetus-base/mixins'
import vuetify from './packages/cetus-base/plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'nprogress/nprogress.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'

import loader from './packages/cetus-base/plugins/loader'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import confirmation from './packages/cetus-base/plugins/confirmtion'

/** packages */
import Dashboard from './packages/cetus-base'
import Repossession from './packages/cetus-repossession'
import Storage from './packages/cetus-storage'

// eslint-disable-next-line no-global-assign
Event = new Vue()

Vue.use(confirmation)

filters.forEach(f => {
  Vue.filter(f.name, f.execute)
})

const options = { router, store }

Vue.config.productionTip = false
Vue.use(VueSweetalert2)
Vue.use(Toast)
Vue.use(loader, options)
Vue.use(Dashboard, options)
Vue.use(Repossession, options)
Vue.use(Storage, options)

new Vue({
  router,
  store,
  mixins: [status, listener],
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
