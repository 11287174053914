<template>
  <v-main>
      <router-view/>
  </v-main>
</template>
<script>
export default {
  name: 'Storage',
  data: function () {
    return {}
  }
}
</script>
