<template>
  <div class="px-1">
    <div class="text-h5" style="text-align: center;">Login In</div>
    <v-card-text>
      <v-form ref="loginForm" @submit.prevent="login">
        <v-text-field
          v-model="formData.email"
          dense
          label="Email Address"
          append-icon="email"
          :rules="[rules.email.required, rules.email.value]"
          filled
        ></v-text-field>
        <v-text-field
          v-model="formData.password"
          filled
          dense
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :rules="[rules.password.required, rules.password.min]"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          label="Password"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <div>
          <router-link to="/auth/reset"
            ><small>Forgot password?</small></router-link
          >
        </div>
        <v-btn class="mt-2" color="primary" block type="submit">
          <div>Login</div>
        </v-btn>
      </v-form>

      <div>
        <p style="color: #009933;" class="d-flex justify-end mt-5"><small>Do not have an account??</small></p>
        <v-btn color="info" style="margin-top: -4%;" block outlined class="btnActions" @click="request">Request credentials</v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Login',
  data: () => ({
    formData: {
      email: '',
      password: '',
      device_name: 'apollo'
    },
    showPassword: false,
    rules: {
      password: {
        required: (value) => !!value || 'Password is Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters'
      },
      email: {
        required: (value) => !!value || 'Email is Required.',
        value: (value) => /.+@.+/.test(value) || 'E-mail must be valid'
      }
    }
  }),
  computed: {
    ...mapState({
      loading: function (state) {
        return state.loader.loading
      }
    })
  },
  methods: {
    ...mapActions({
      login: function (dispatch, event) {
        /** validate form */
        const { password, email } = this.formData
        if (
          password.length === 0 ||
          password.length < 8 ||
          email.length === 0 ||
          !/.+@.+/.test(email)
        ) {
          this.$refs.loginForm.validate()
        } else {
          dispatch('auth/login', this.formData)
          // this.formData.email = this.formData.password = ''
          event.target.reset()
        }
      }
    }),
    request: function () {
      this.$router.replace({ path: 'request/credentials' })
    }
  }
}
</script>
