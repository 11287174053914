<template>
<div>
 <v-dialog persistent hide-overlay v-model="scanner" content-class="modal--scanner">
    <v-card class="mx-auto">
         <div
          v-if="!scanning"
          class="file--attachment d-flex flex-column"
          :style="{
            border: '3px dashed #1ABC9C',
            background: 'rgb(227 242 253 / 45%)',
          }"
        >
          <div class="align-self-center">
            <div
              class="font-weight-bold d-flex flex-column"
            >
              <div
                v-ripple="{ center: true }"
                class="file--avatar--outline align-self-center"
              >
                <!-- uploader -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                          :color="`teal lighten-3`"
                          :size="110"
                          class="file--avatar"
                          @click="scanCode"
                          v-on="on"
                          v-bind="attrs"
                      >
                          <v-icon
                          :color="`teal darken-4`"
                          :size="Math.round((8 / 11) * 110)"
                          >
                          qr_code_scanner
                          </v-icon>
                      </v-avatar>
                  </template>
                  <span>Click Scan Barcode/QR code</span>
                </v-tooltip>
              </div>
              <div>Click to Scan Your Barcode or QR code</div>
              <div class="or--text"><span>or</span></div>
            </div>
          </div>
          <div class="mx-2">
            <v-alert v-if="timeout" type="info" dense text dismissible>
                Scanner Timeout! Enter the code to continue
            </v-alert>
            <v-text-field
                v-model="code"
                outlined
                dense
                class="code--form"
                prepend-inner-icon="qr_code_2"
                placeholder="Enter Vehicle ID"
            >
                <template v-slot:prepend-inner>
                    <v-icon size="30"> qr_code_2 </v-icon>
                </template>
                <template v-slot:append>
                    <v-btn
                        :disabled="code === null || code.length === 0"
                        elevation="0"
                        class="primary code--button"
                        @click="submitCode"
                    >
                        Submit
                    </v-btn>
                </template>
            </v-text-field>
          </div>
        </div>
        <div v-else class="d-flex justify-center">
          <v-progress-circular
                v-if="loading"
                class="align-self-center my-3"
                :size="50"
                :width="2"
                color="primary"
                indeterminate
          ></v-progress-circular>
          <StreamBarcodeReader
            ref="scanner"
            @decode="(a, b, c) => onDecode(a, b, c)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </div>
    </v-card>
 </v-dialog>
</div>
</template>
<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
export default {
  name: 'StorageScanner',
  props: { scan: Boolean },
  components: {
    StreamBarcodeReader
  },
  data: function () {
    return {
      scanning: false,
      code: '',
      timeout: false,
      loading: true
    }
  },
  computed: {
    scanner: {
      get () {
        return this.scan
      },
      set (value) {
        this.updateScan(value)
      }
    }
  },
  watch: {
    scanning () {
      if (this.scanning) {
        setTimeout(() => {
          if (this.code.length === 0) this.timeout = true
          if (this.scanning) this.closeCamera() /** second conditional statement to check scanning  */
        }, 18000000)
      }
    }
  },
  methods: {
    updateScan (val) {
      this.$emit('updateScan', val)
    },
    onDecode (a, b, c) {
      this.code = a
      if (this.id) clearTimeout(this.id)
      this.id = setTimeout(() => {
        if (this.code === a && this.scanning) {
          this.code = ''
        }
      }, 5000)
      if (this.code.length !== 0) {
        this.closeCamera()
      }
    },
    onLoaded () {
      this.loading = false
    },
    scanCode () {
      this.scanning = true
    },
    submitCode () {
      this.updateScan({ scan: false, code: this.code })
    },
    closeCamera () {
      if (this.$refs.scanner !== undefined) {
        this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      this.scanning = false
      if (this.code.length !== 0) this.submitCode()
    }
  }
}
</script>
<style>
@import url('../styles.css');
</style>
