import Dashboard from '../../cetus-base/modules/dashboard/views/DashboardLayout.vue'
import Storage from './views/Storage.vue'
import StorageList from './components/StorageList.vue'
import StorageCard from './components/StorageCard.vue'
import StorageCharges from './components/StorageCharges.vue'
import Auth from '../../../router/middleware/Auth'

export default [
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        redirect: '/dashboard/storage'
      },
      {
        path: 'storage',
        component: Storage,
        children: [
          {
            path: 'list/:status?',
            name: 'Storage',
            component: StorageList,
            meta: {
              middleware: Auth,
              text: 'Storage List'
            }
          },
          {
            path: 'storagecard',
            name: 'StorageCard',
            component: StorageCard,
            meta: {
              middleware: Auth,
              text: 'Storage Card'
            }
          },
          {
            path: 'storageCharges/:no',
            name: 'StorageCharges',
            component: StorageCharges,
            meta: {
              middleware: Auth,
              text: 'Storage Charges'
            }
          }
        ]
      }
    ]
  }
]
