<template>
    <img src="../assets/eazy.jpg" :width="size" />
</template>

<script>
export default {
  name: 'Logo',
  props: {
    size: {
      type: String,
      default: '60'
    }
  }
}
</script>

<style scoped />
