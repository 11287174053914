<template>
    <v-footer class="footer" fixed padless style="background-color: #F7F6FE;">
        <v-col
        class="text-center"
        cols="12"
        >
         © {{ new Date().getFullYear() }} — Kinetics Technology LTD
        </v-col>
    </v-footer>
</template>
<style>
  .footer {
  background: #F7F6FE;
  width: 30%;
  margin-left: 35%;
}
@media only screen and (max-width: 768px) {
  .footer {
    background: #F7F6FE;
    width: 100%;
    margin-left: 0;
  }
}
</style>
<script>
export default {
  name: 'AuthFooter'
}
</script>

<style scoped></style>
