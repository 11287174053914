<template>
    <div v-if="job !== undefined">
        <v-carousel height="85vh" v-model="imageOnView">
            <v-carousel-item
            v-for="(item,i) in getPhotos(job.repossesionAttachment, 'All')"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
            >
              <v-card flat tile style="background: #F7F6FE;">
                <div class="py-3 px-2" style="color: #000;">
                  Description: {{ item.description }}
                </div>
              </v-card>

              <div v-if="isPdf(item.attachment)" style="max-height: 60vh; overflow-y: scroll">
                <pdf
                  v-for="i in pageCount(item.attachment)" :key="i"
                  :page="i"
                  style="height: auto; width: 100%;"
                  :src="`data:application/pdf;base64,${item.attachment}`"
                />
              </div>

              <v-img
                v-else
                :src="`data:image/png;base64,${ item.attachment }`"
                contain
              />

              <div v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')" style="position: absolute; bottom: 13%; width: 100%;" class="px-2">
                <v-row>
                  <v-col cols="6">
                    <v-btn block color="error" @click="removeAttachments()" class="btnActions">
                      <v-icon class="mx-2">delete</v-icon>
                        Delete
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block color="primary" @click="imageDialog = { open: true }" class="btnActions">
                      <v-icon class="mx-2">update</v-icon>
                        Edit Image
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-carousel-item>
        </v-carousel>
        <ImageModal :job="job" />
    </div>
</template>
<style>
  @import url('../styles.css');
</style>
<script>
import RepoMethods from '../RepoMethods'
import ImageModal from './ImageModal'
import pdf from 'vue-pdf'

export default {
  name: 'ImageCard',
  components: { ImageModal, pdf },
  data: function () {
    return {
      line_no: 0,
      imageOnView: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      const { jobCode } = v.$route.params
      v.getJob(atob(jobCode))
      v.$store.dispatch('reposession/updatePrevRoute', from)
    })
  },
  mounted () {
    this.line_no = this.$route.query.line_no
  },
  computed: {
    userData: function () {
      return this.$store.getters['auth/user']
    },
    job: function () {
      // eslint-disable-next-line camelcase
      return this.$store.getters['reposession/getJobObject']()
    },
    imageDialog: {
      get () {
        return this.$store.getters['reposession/dashboardGetter']('imageDialog').open
      },
      set (value) {
        const attachment = this.getPhotos(this.job.repossesionAttachment, 'All')[this.imageOnView]
        this.$store.commit('reposession/MUTATE', { state: 'imageDialog', data: { open: value, type: 1, attachment } })
      }
    }
  },
  methods: {
    ...RepoMethods
  }
}
</script>
