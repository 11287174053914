<template>
        <div>
            <div class="text-h5" style="text-align: center;">
              Request Credentials
            </div>
            <v-card-text>
              <v-form ref="requestCredentials" @submit.prevent="requestCredentials">
                <v-text-field
                  v-model="formData.email"
                  filled
                  dense
                  label="Email Address"
                  append-icon="email"
                  :rules="[rules.email.required, rules.email.value]"
                ></v-text-field>
                <v-btn
                  class="mt-2"
                  color="primary"
                  block
                  type="submit"
                >
                  <div>Request Credentials</div>
                </v-btn>
              </v-form>
              <div>
                <p style="color: #009933;" class="d-flex justify-end mt-5"><small>Already have an account?</small></p>
                <v-btn color="info" style="margin-top: -4%;" block outlined class="btnActions" @click="login">Login</v-btn>
              </div>
            </v-card-text>
        </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Login',
  components: { },
  data: () => ({
    formData: {
      email: '',
      device_name: 'cetus'
    },
    showPassword: false,
    rules: {
      email: {
        required: (value) => !!value || 'Email address is Required.',
        value: (value) => /.+@.+/.test(value) || 'E-mail must be valid'
      }
    }
  }),
  computed: {
    ...mapState({
      loading: function (state) {
        return state.loader.loading
      }
    })
  },
  methods: {
    ...mapActions({
      requestCredentials: function (dispatch, event) {
        /** validate form */
        const { email } = this.formData
        if (email.length === 0 || !(/.+@.+/.test(email))) {
          this.$refs.requestCredentials.validate()
        } else {
          /** request credentials */
          dispatch('auth/requestCredentials', this.formData)
          event.target.reset()
        }
      }
    }),
    login: function () {
      this.$router.replace({ path: '/auth/login' })
    }
  }
}
</script>
