<template>
  <div class="mx-2 my-2">
    <div class="text-lg-h6 ml-1">Reposession</div>
    <v-row no-gutters>
      <v-col
        class="py-2"
        cols="6"
        md="6"
        v-for="(item, i) in getJobsStats"
        :key="i"
      >
        <v-card
          @click="
            item.job.length === 1 ? viewCard(item.job[0].no) : viewCards(item)
          "
          :key="i"
          class="mx-1 cardStyle"
          flat
          style="background-color: #ffffff"
        >
          <v-list>
            <v-list-item dense>
              <v-list-item-content class="stats">
                <div class="text-h4">
                  {{ item.count }}
                </div>
                <v-list-item-subtitle>{{ item.status }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar
                  style="position: absolute; top: 1%; right: 5%"
                  :color="item.avatarColor"
                  size="40"
                >
                  <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-expansion-panels accordion focusable flat class="current--job">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-badge
              color="blue darken-4"
              :content="getJobByStatus.length.toString()"
              right
              offset-y="100%"
              offset-x="-5%"
            >
              Current Job Card(s)
            </v-badge>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="transparent">
          <div class="mt-2" v-for="(item, i) in getJobByStatus" :key="i">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
                class="cardStyle mb-7"
                flat
                style="cursor: pointer"
                :key="i"
                ripple
                @click="viewCard(item.no)"
                :elevation="hover ? 4 : 0"
              >
                <div>
                  <v-list-item-avatar
                    class="mx-1"
                    color="blue lighten-1"
                    style="color: #fff"
                    >{{ item.agent_code }}</v-list-item-avatar
                  >
                  <v-chip class="mx-1" label>Doc No: {{ item.no }}</v-chip>
                  <v-chip
                    style="position: absolute; top: 1em; right: 0.5em"
                    :color="item.statusColor"
                    label
                    >{{ item.document_status }}</v-chip
                  >
                  <v-list dense>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px"
                          >Customer Name</v-list-item-title
                        >
                        <v-chip
                          label
                          style="font-weight: bold; font-size: 17px"
                          >{{ item.name }}</v-chip
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px"
                          >Vehicle Registration Number</v-list-item-title
                        >
                        <v-chip
                          label
                          style="font-weight: bold; font-size: 17px"
                          >{{ item.vehicle_registration_no }}</v-chip
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <v-divider />
              </v-card>
            </v-hover>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="text-lg-h6 ml-1">Storage</div>
    <v-row no-gutters>
      <v-col
        class="py-2"
        cols="6"
        md="6"
        v-for="(item, i) in storageStats"
        :key="i"
      >
        <v-card
          @click="$router.replace({ name: 'Storage', params: item.params })"
          :key="i"
          class="mx-1 cardStyle"
          flat
          style="background-color: #ffffff"
        >
          <v-list>
            <v-list-item dense>
              <v-list-item-content class="stats">
                <div class="text-h4">
                  {{ item.count }}
                </div>
                <v-list-item-subtitle>{{ item.status }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar
                  style="position: absolute; top: 1%; right: 5%"
                  :color="item.avatarColor"
                  size="40"
                >
                  <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div style="margin-bottom: 14%"></div>
  </div>
</template>
<style>
@import url("../styles.css");
</style>
<script>
import RepoMethods from '../RepoMethods'
export default {
  name: 'Main',
  data: () => ({}),
  computed: {
    getJobsStats: function () {
      return this.$store.getters['reposession/getJobsStats']
    },
    getAllJobs: function () {
      return this.$store.getters['reposession/dashboardGetter']('jobs')
    },
    getJobByStatus: function () {
      return this.$store.getters['reposession/getJobByStatus']('Released')
    },
    storageItems: function () {
      return this.$store.getters['storage/storageGetters']('storage')
    },
    storageStats (state) {
      return [
        {
          count: this.storageItems.filter(i => i.status === 'New').length,
          status: 'New',
          avatarColor: 'orange lighten-4',
          iconColor: 'orange darken-4',
          icon: 'view_day',
          params: { status: 'New' }
        },
        {
          count: this.storageItems.filter(i => i.status === 'Booked').length,
          status: 'Booked',
          avatarColor: 'teal lighten-4',
          iconColor: 'teal darken-4',
          icon: 'archive',
          params: { status: 'Booked' }
        },
        {
          count: this.storageItems.filter(i => i.status === 'Check Out').length,
          status: 'Check Out',
          avatarColor: 'green lighten-4',
          iconColor: 'green darken-4',
          icon: 'pending',
          params: { status: 'Check Out' }
        },
        {
          count: this.storageItems.filter(i => i.status === 'Closed').length,
          status: 'Closed',
          avatarColor: 'cyan lighten-4',
          iconColor: 'cyan darken-4',
          icon: 'subtitles',
          params: { status: 'Closed' }
        }
      ]
    }
  },
  watch: {
    getAllJobs () {}
  },
  methods: {
    ...RepoMethods
  }
}
</script>
