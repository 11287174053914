<template>
  <div class="mt-n4">
    <v-dialog v-model="AddChargesDialog" content-class="modal--scanner">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            dark
            class="text-capitalize mb-12 add--charges"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon class="mx-3">fact_check</v-icon>
            Add Storage Charges
          </v-btn>
        </div>
      </template>
      <v-card class="mx-auto">
        <div class="text-md-body-1 py-2 mx-3 font-weight-bold d-flex justify-space-between">
          <div class="pt-2">Add Storage Charges</div>
          <v-btn icon @click="AddChargesDialog = false" color="red">
              <v-icon size="30">close</v-icon>
          </v-btn>
        </div>
        <div class="mx-3">
          <v-list class="mt-n3">
            <v-list-item-group
              active-class="primary--text"
              v-model="formData.storageLines"
              multiple
            >
              <v-list-item
                dense
                v-for="(item, i) in charges"
                :key="i"
                :value="item"
                :disabled="item.mandatory"
                class="my-2"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    ><v-checkbox
                      :input-value="active"
                      color="primary"
                    ></v-checkbox
                  ></v-list-item-action>
                  <v-list-item-title>{{ item.description }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.amount | currency }}
                  </v-list-item-subtitle>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div class="d-flex justify-space-between mx-2">
          <div class="font-weight-bold text-subtitle-2 mx-2">Total:</div>
          <div class="mx-2">{{ total | currency }}</div>
        </div>
        <v-card-actions>
          <v-btn :disabled="this.formData.storageLines.length === 0" @click="addStoragCharges" block bottom elevation="0" color="cyan darken-3 white--text">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'AddCharges',
  props: { storageCard: Object },
  data: function () {
    return {
      AddChargesDialog: false,
      formData: {
        storageLines: []
      }
    }
  },
  mounted () {
    if (this.storageCard.storageLines.length === 0) {
      this.formData.storageLines = this.charges.filter((el) => el.mandatory)
    }
  },
  computed: {
    total: function () {
      return this.formData.storageLines
        ? this.formData.storageLines.reduce((a, b) => a + b.amount, 0)
        : 0
    },
    charges: function () {
      return this.$store.getters['storage/storageGetters']('storageCharges').filter((el) => {
        if (this.storageCard.storageLines.length !== 0) {
          el.mandatory = false
          return this.storageCard.storageLines.filter((item) => item.storageCode === el.code).length === 0
        } else {
          return el
        }
      })
    }
  },
  methods: {
    addStoragCharges: function () {
      const data = this.formData.storageLines.map((el) => {
        const { amount, description, code } = el
        return { amount, description, storageCode: code, documentNo: this.storageCard.no }
      })
      this.$store.dispatch('storage/addStorageLines', data)
      this.AddChargesDialog = false
    }
  }
}
</script>
<style>
@import url("../styles.css");
</style>
