import auth from './views/auth'
import Landing from './views/components/Landing'
import setPassword from './views/components/SetPassword'
import ForgotPassword from './views/components/ForgotPassword'

export default [
  {
    path: '/auth',
    component: auth,
    children: [
      {
        path: '',
        redirect: '/auth/login'
      },
      {
        path: 'request/credentials',
        name: 'requestCredentials',
        component: Landing,
        meta: {
          isLogin: false
        }
      },
      {
        path: 'login',
        name: 'login',
        component: Landing,
        meta: {
          isLogin: true
        }
      },
      {
        path: 'setPassword/:token',
        name: 'setPassword',
        component: setPassword
      },
      {
        path: 'reset',
        name: 'reset',
        component: ForgotPassword
      }
    ]
  }
]
