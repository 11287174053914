<template>
<div>
  <v-dialog class="loadDialog" persistent :no-click-animation="true" :value="loading" overlay-opacity="0" width="95" origin="center center">
    <!-- <v-card class="px-2 py-2"> -->
      <Spinner class="spinner"/>
    <!-- </v-card> -->
  </v-dialog>
  <router-view />
</div>
</template>

<style>
</style>
<script>
import { mapState } from 'vuex'
import Spinner from '../../../plugins/loader/views/Spinner'
export default {
  name: 'Auth',
  components: { Spinner },
  beforeCreate () {
    document.body.className = 'gray-bg'
  },
  computed: {
    ...mapState({
      loading: function (state) {
        return state.loader.loading
      }
    })
  }
}
</script>

<style></style>
