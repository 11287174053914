<template>
  <div>
    <v-form v-model="isValid" ref="storageForm">
      <v-card-text class="mt-n6">
        <div>
          <div
            class="
              font-weight-bold
              text-subtitle-2
              d-flex
              justify-space-between
            "
          >
            <div>Vehicle Registration Number</div>
            <div class="text-caption deep-orange--text" v-if="isSubmit">
              required*
            </div>
          </div>
          <v-text-field
            outlined
            :filled="formData.vehicleRegNo.length !== 0"
            prepend-inner-icon="directions_car"
            :readonly="!isSubmit"
            dense
            v-model="formData.vehicleRegNo"
            :rules="rules"
            placeholder="Enter Vehicle Registration Number"
          ></v-text-field>
        </div>
        <div class="mt-n4" v-if="!isSubmit">
          <div
            class="
              font-weight-bold
              text-subtitle-2
              d-flex
              justify-space-between
            "
          >
            <div>Document Date</div>
            <div class="text-caption deep-orange--text" v-if="isSubmit">
              required*
            </div>
          </div>
          <v-text-field
            filled
            outlined
            prepend-inner-icon="today"
            dense
            :readonly="!isSubmit"
            v-model="formData.documentDate"
            :rules="rules"
            type="date"
            placeholder="Enter Start Date"
          ></v-text-field>
        </div>
        <div class="mt-n4" v-if="isSubmit">
          <div
            class="
              font-weight-bold
              text-subtitle-2
              d-flex
              justify-space-between
            "
          >
            <div>Full Name</div>
            <div class="text-caption deep-orange--text" v-if="isSubmit">
              required*
            </div>
          </div>
          <v-text-field
            :filled="formData.fullName.length !== 0"
            outlined
            prepend-inner-icon="account_circle"
            dense
            :readonly="!isSubmit"
            :rules="rules"
            v-model="formData.fullName"
            placeholder="Enter Full Name"
          ></v-text-field>
        </div>
        <div class="mt-n4">
          <div
            class="
              font-weight-bold
              text-subtitle-2
              d-flex
              justify-space-between
            "
          >
            <div>Location</div>
            <div class="text-caption deep-orange--text" v-if="isSubmit">
              required*
            </div>
          </div>
          <v-select
            :filled="formData.location.length !== 0"
            outlined
            prepend-inner-icon="location_on"
            dense
            :items="yards"
            item-text="description"
            item-value="code"
            :readonly="!isSubmit"
            :rules="rules"
            v-model="formData.location"
            placeholder="Enter Yard Location"
          ></v-select>
        </div>
        <div class="mt-n4">
          <div
            class="
              font-weight-bold
              text-subtitle-2
              d-flex
              justify-space-between
            "
          >
            <div>refNo/mmcode</div>
            <div class="text-caption deep-orange--text" v-if="isSubmit">
              required*
            </div>
          </div>
          <v-text-field
            :filled="formData.refNo.length !== 0"
            outlined
            prepend-inner-icon="dialpad"
            dense
            :readonly="!isSubmit"
            :rules="rules"
            v-model="formData.refNo"
            placeholder="Enter Reference Number"
          ></v-text-field>
        </div>
        <v-btn
          color="primary"
          dark
          block
          class="text-capitalize"
          v-if="formData.status !== 'New' && !isSubmit"
          @click="$router.replace({ name: 'StorageCharges', params: { no: formData.no } })"
        >
          <v-icon class="mx-3">monetization_on</v-icon>
          View Accrued Charges
        </v-btn>
        <div>
          <div class="font-weight-bold text-subtitle-2">comments</div>
          <v-textarea
            :filled="formData.comment.length !== 0"
            outlined
            prepend-inner-icon="comment"
            dense
            :readonly="!isSubmit"
            v-model="formData.comment"
            rows="2"
            placeholder="Enter comments"
          ></v-textarea>
        </div>
      </v-card-text>
    </v-form>
    <v-card-text class="mt-n5">
      <div class="mt-n4 font-weight-bold text-subtitle-1">Checklist</div>
      <div v-if="isSubmit">
        <FileUpload
          :IsImage="false"
          :Icon="'fact_check'"
          :Outlined="true"
          @updateFile="uploadFile"
          :status="fileStatus"
          v-if="isSubmit"
        />
        <v-btn block color="primary" class="my-10" bottom @click="submit">{{
          `${isEdit ? "Update" : "Submit"}`
        }}</v-btn>
      </div>
      <div v-else>
        <div v-if="isImage(formData.checkListAttachment)">
          <v-img
            :src="`data:image/png;base64,${formData.checkListAttachment}`"
          ></v-img>
        </div>
        <div v-else>
          <pdf
            class="mx-1 my-1 checklist--pdf"
            :src="`data:application/pdf;base64,${formData.checkListAttachment}`"
          />
        </div>
      </div>
    </v-card-text>
    <StorageScanner
      v-if="isSubmit && !isEdit"
      @updateScan="setScanner"
      :scan="scan"
    />
  </div>
</template>
<script>
import FileUpload from './FileUpload.vue'
import pdf from 'vue-pdf'
import helpers from '../../../cetus-base/utilities/helpers/helper.js'
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'
import StorageScanner from './StorageScanner.vue'
export default {
  name: 'StorageForm',
  components: { FileUpload, pdf, StorageScanner },
  props: { isSubmit: Boolean, scan: Boolean, isEdit: Boolean },
  data: function () {
    return {
      formData: {
        barcode: '',
        checkListAttachment: '',
        comment: '',
        fullName: '',
        vehicleRegNo: '',
        refNo: '',
        location: ''
      },
      isValid: false,
      qr_code: '',
      fileStatus: 'default',
      rules: [(v) => !!v || 'Field is required']
    }
  },
  computed: {
    userData: function () {
      return this.$store.getters['auth/user']
    },
    storageCard: function () {
      return this.$store.getters['storage/storageGetters']('storageCard')
    },
    yards: function () {
      return this.$store.getters['storage/storageGetters']('yards')
    },
    valuationData: function () {
      return this.$store.getters['storage/storageGetters']('valuationData')
    }
  },
  watch: {
    storageCard () {
      this.formData = this.storageCard
    },
    valuationData () {
      this.formData.vehicleRegNo = this.valuationData.reg_no
      this.formData.refNo = this.valuationData.mmcode
      this.formData.fullName = this.valuationData.valuator
    }
  },
  methods: {
    uploadFile: async function (value) {
      if (value.file.name !== undefined) {
        this.fileStatus = 'success'
        this.formData.checkListAttachment = await helpers.toBase64(value.file)
        this.formData.checkListAttachment =
          this.formData.checkListAttachment.split(',')[1]
      } else {
        this.formData.checkListAttachment = ''
      }
    },
    submit: function () {
      /** submit storage card */
      if (!this.isValid) {
        this.$refs.storageForm.validate()
      } else {
        if (this.formData.checkListAttachment.length === 0) {
          this.fileStatus = 'error'
          Event.$emit('ApiError', 'Checklist Attachment is Required')
        } else {
          this.formData.agentCode = this.userData.agent_code
          this.isEdit
            ? this.$store.dispatch('storage/updateStorage', {
              ...this.formData
            })
            : this.$store.dispatch('storage/submitStorage', {
              ...this.formData
            })
        }
      }
    },
    getBarCodeImg: function ({ code, scan }) {
      this.fetchValuation(code.toString())
      const canvas = document.createElement('canvas')
      /** barcode */
      JsBarcode(canvas, code.toString())
      this.formData.barcode = canvas.toDataURL('image/png').split(',')[1]
      /** qr code */
      QRCode.toCanvas(canvas, code.toString())
      this.qr_code = canvas.toDataURL('image/png').split(',')[1]
      /** emit event to parent component */
      this.$emit('displayScanned', {
        barcode: this.formData.barcode,
        qr_code: this.qr_code,
        scan
      })
    },
    setScanner: function (value) {
      this.getBarCodeImg(value)
    },
    fetchValuation: function (value) {
      this.$store.dispatch(
        'storage/fetchValuation',
        /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
          ? value.split('|')[0].split('_')[1]
          : value
      )
    },
    isImage: function (base64) {
      return helpers.detectMimeType(base64) !== 'application/pdf'
    }
  }
}
</script>
<style>
.checklist--pdf {
  height: 100%;
  width: 100%;
}
</style>
