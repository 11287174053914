<template>
    <v-dialog
        v-model="searchDialog"
        fullscreen
        transition="dialog-top-transition"
        content-class="search"
    >
     <v-card class="mx-auto search--card" flat>
          <v-text-field
            v-model="searchField"
            dense
            clearable
            prepend-inner-icon="search"
            filled
            placeholder="Search ...."
          ></v-text-field>
     </v-card>
    </v-dialog>
</template>
<style>
    @import url('./styles.css');
</style>
<script>
export default {
  name: 'Search',
  props: { search: String },
  data: () => ({}),
  computed: {
    searchDialog: {
      set (value) {
        this.$store.dispatch('reposession/setSearchDialog', value)
      },
      get () {
        return this.$store.getters['reposession/getSearchDialog']
      }
    },
    searchField: {
      get () {
        return this.search
      },
      set (value) {
        this.$emit('searchInput', value)
      }
    }
  }
}
</script>
