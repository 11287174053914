<template>
  <v-container>
    <div style="text-align: center; display: block;">
      <Logo class="py-10"/>
    </div>
        <div>
            <div class="py-5 text-h5" style="text-align: center;">
              Enter Email Address to reset your Password
            </div>
              <v-form ref="sendLink" v-model="isValid" @submit.prevent="sendLink">
                <v-text-field
                    v-model="formData.email"
                    filled
                    dense
                    label="Email Address"
                    append-icon="email"
                    :rules="[rules.email.required, rules.email.value]"
                ></v-text-field>
                <v-btn
                  class="mt-2"
                  color="primary"
                  block
                  type="submit"
                >
                  <div v-if="setLoading">
                    <v-progress-circular
                      :size="30"
                      :width="3"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <div v-else>Send Link</div>
                </v-btn>
              </v-form>
        </div>
    <auth-footer />
  </v-container>
</template>

<style>
  .loginCard {
    width: 40%;
  }
  @media only screen and (max-width: 600px) {
    .loginCard {
      width: 100%;
    }
  }
</style>
<script>
import Logo from '../../../../components/Logo'
import AuthFooter from '../../../../components/AuthFooter'
import { mapActions } from 'vuex'
export default {
  name: 'ForgotPassword',
  components: { AuthFooter, Logo },
  data: () => ({
    formData: {
      email: '',
      device_name: 'apollo'
    },
    isValid: false,
    rules: {
      email: {
        required: (value) => !!value || 'Email is Required.',
        value: (value) => /.+@.+/.test(value) || 'E-mail must be valid'
      }
    }
  }),
  created () {
    this.formData.auth_token = this.$route.params.token
  },
  computed: {
    setLoading: function () {
      return this.$store.state.loader.loading
    }
  },
  methods: {
    ...mapActions({
      sendLink: function (dispatch, event) {
        /** validate form */
        if (!this.isValid) {
          this.$refs.sendLink.validate()
        } else {
          dispatch('auth/sendLink', this.formData)
        }
      }
    })
  }
}
</script>
