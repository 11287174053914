<template>
  <v-container>
    <div style="text-align: center; display: block;">
      <Logo class="py-10"/>
    </div>
        <div>
            <div class="py-5 text-h5" style="text-align: center;">
              Set Password
            </div>
              <v-form ref="setPasswordForm" @submit.prevent="setPassword">
                <v-text-field
                  v-model="formData.password"
                  filled
                  dense
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :rules="[rules.password.required, rules.password.min]"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  label="Password"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <div :style="{ color: strengthColor, marginTop: strengthValue.length === 0 ? '-5%' : '-10%',
                textAlign: 'right' }" class="py-2">{{ strengthValue }}</div>
                <password style="margin-top: -4%;" @score="showScore" v-model="formData.password"
                :strength-meter-only="true"/>
                <v-text-field
                  v-model="formData.confirmPassword"
                  filled
                  dense
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :rules="[rules.password.required, rules.password.min]"
                  :type="showPassword ? 'text' : 'password'"
                  name="confirmPassword"
                  label="Confirm password"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-btn
                  class="mt-2"
                  color="primary"
                  block
                  type="submit"
                >
                  <div v-if="setLoading">
                    <v-progress-circular
                      :size="30"
                      :width="3"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <div v-else>Set Password</div>
                </v-btn>
              </v-form>
        </div>
    <auth-footer />
  </v-container>
</template>

<style>
@import url('../../styles.css');
</style>
<script>
import Logo from '../../../../components/Logo'
import AuthFooter from '../../../../components/AuthFooter'
import { mapActions } from 'vuex'
import Password from 'vue-password-strength-meter'
export default {
  name: 'Login',
  components: { AuthFooter, Logo, Password },
  data: () => ({
    formData: {
      confirmPassword: '',
      password: '',
      token: '',
      device_name: 'cetus'
    },
    showPassword: false,
    strengthValue: '',
    strengthColor: '',
    rules: {
      password: {
        required: (value) => !!value || 'Password is Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters'
      }
    }
  }),
  created () {
    this.formData.token = this.$route.params.token
  },
  computed: {
    setLoading: function () {
      return this.$store.state.loader.loading
    }
  },
  methods: {
    ...mapActions({
      setPassword: function (dispatch, event) {
        /** validate form */
        const { password, confirmPassword } = this.formData
        if (password.length === 0 || password.length < 8) {
          this.$refs.setPasswordForm.validate()
        } else if (confirmPassword !== password) {
          /** error */
          Event.$emit('ApiError', 'Passwords do not match')
        } else {
          dispatch('auth/setPassword', this.formData)
          event.target.reset()
        }
      }
    }),
    showScore (score) {
      if (score === null) {
        this.strengthValue = ''
        this.strengthColor = ''
      } else if (score < 3) {
        this.strengthValue = 'fair'
        this.strengthColor = '#F39C12'
      } else if (score === 3) {
        this.strengthValue = 'strong'
        this.strengthColor = '#58D68D'
      } else if (score === 4) {
        this.strengthValue = 'perfect'
        this.strengthColor = 'green'
      }
    }
  }
}
</script>
