import Proclamation from './Proclamation'
import ProcForm from './views/ProcForm'

export default [
  {
    path: '/proclamation',
    component: Proclamation,
    children: [
      {
        path: '',
        redirect: '/proclamation/proc_form'
      },
      {
        path: 'proc_form',
        name: 'proc_form',
        component: ProcForm,
        meta: {
          text: 'Proclamation Form'
        }
      }
    ]
  }
]
