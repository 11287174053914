<template>
<div>
  <v-dialog class="loadDialog" persistent :no-click-animation="true" :value="loading" overlay-opacity="0" width="95" origin="center center">
    <!-- <v-card class="px-2 py-2"> -->
      <Spinner class="spinner"/>
    <!-- </v-card> -->
  </v-dialog>
  <v-app-bar tile fixed class="appBar">
    <div>
      <v-list-item :ripple="false" style="margin-left: -4%;">
        <v-list-item-content>
          <v-list-item-title>Welcome</v-list-item-title>
          <v-list-item-subtitle>{{ userData.name }}, {{ userData.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-spacer></v-spacer>
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="Object.keys(userData).length !== 0" icon large v-bind="attrs" v-on="on">
          <v-avatar color="primary">
            <span style="color: #fff;">{{ userData.email[0].toUpperCase() + userData.email[1].toUpperCase() }}</span>
          </v-avatar>
        </v-btn>
      </template>
        <v-list dense>
          <v-list-item @click="logout" ripple dense>
            <v-list-item-title>Logout</v-list-item-title>
              <v-list-item-action>
                <v-icon>logout</v-icon>
              </v-list-item-action>
          </v-list-item>
        </v-list>
    </v-menu>
  </v-app-bar>
  <div class="mt-6 pt-10">
    <router-view />
  </div>
  <!-- <BottomNavigation /> -->
  <auth-footer/>
</div>
</template>

<style>
.appBar {
  width: 30%;
  margin-left: 35%;
}
@media only screen and (max-width: 768px) {
  .appBar {
    width: 100%;
    margin-left: 0;
  }
}
</style>
<script>
import { mapState } from 'vuex'
import Spinner from '../../../../cetus-base/plugins/loader/views/Spinner'
import AuthFooter from '../../../../cetus-base/components/AuthFooter.vue'
import ProclamationMethods from './ProclamationMethods'
// import BottomNavigation from '../../components/BottomNavigation'
export default {
  name: 'Proclamation.',
  components: { Spinner, AuthFooter },
  data: () => ({
    menu: null
  }),
  mounted () {},
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.$store.dispatch('proclamation/getSetupData')
    })
  },
  computed: {
    ...mapState({
      loading: function (state) {
        return state.loader.loading
      }
    }),
    userData: function () {
      return this.$store.getters['auth/user']
    }
  },
  watch: {
  },
  methods: {
    ...ProclamationMethods
  }
}
</script>
