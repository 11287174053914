<template>
  <div>
    <v-dialog
      class="loadDialog"
      persistent
      :no-click-animation="true"
      :value="loading"
      overlay-opacity="0"
      width="95"
      origin="center center"
    >
      <!-- <v-card class="px-2 py-2"> -->
      <Spinner class="spinner" />
      <!-- </v-card> -->
    </v-dialog>
    <v-app-bar tile fixed class="appBar">
      <v-app-bar-nav-icon v-if="currentRoute !== 'main'">
        <v-btn icon @click="back">
          <v-icon large>chevron_left</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <div>
        <v-list-item
          v-if="currentRoute === 'main'"
          :ripple="false"
          style="margin-left: -4%"
        >
          <v-list-item-content>
            <v-list-item-title>Welcome</v-list-item-title>
            <v-list-item-subtitle
              >{{ userData.name }}, {{ userData.email }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else :ripple="false" style="margin-left: -4%">
          <v-list-item-content>
            <v-list-item-title>{{ routeMetaText }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-spacer></v-spacer>
      <v-menu
        v-model="menu"
        :close-on-content-click="true"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large v-bind="attrs" v-on="on">
            <v-avatar color="primary">
              <span style="color: #fff">{{
                userData.email[0].toUpperCase() +
                userData.email[1].toUpperCase()
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="logout" ripple dense>
            <v-list-item-title>Logout</v-list-item-title>
            <v-list-item-action>
              <v-icon>logout</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-btn
        color="teal lighten-5 teal--text"
        class="scroll--btn text-lowercase"
        v-scroll="onScroll"
        @click="scrollXY"
        v-show="scroll"
        rounded
        elevation="20"
      >
        <v-icon class="mx-2">{{ scrollDirection === 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon>
        {{ scrollDirection === 0 ? 'back top' : 'view more' }}
    </v-btn>
    <div class="mt-4 pt-10 app--root">
      <router-view />
    </div>
    <BottomNavigation />
    <Confirmation />
    <!-- <auth-footer/> -->
  </div>
</template>

<style>
.appBar {
  width: 30%;
  margin-left: 35%;
}
@media only screen and (max-width: 768px) {
  .appBar {
    width: 100%;
    margin-left: 0;
  }
}
</style>
<script>
import { mapState } from 'vuex'
import Spinner from '../../../plugins/loader/views/Spinner'
import BottomNavigation from '../../../components/BottomNavigation.vue'
import Confirmation from '../../../plugins/confirmtion/Confirmation.vue'
export default {
  name: 'App',
  components: { Spinner, BottomNavigation, Confirmation },
  data: () => ({
    menu: null,
    currentRoute: null,
    routeMetaText: '',
    scroll: false,
    scrollDirection: 0,
    lastScrollTop: 0
  }),
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.getJobs()
      v.$store.dispatch('storage/getStorageItems')
      v.$store.dispatch('reposession/updatePrevRoute', from)
    })
  },
  beforeCreate () {
    document.body.className = 'gray-bg'
  },
  mounted () {
    this.currentRoute = this.$route.name
    this.routeMetaText = this.$route.meta.text
  },
  computed: {
    ...mapState({
      loading: function (state) {
        return state.loader.loading
      }
    }),
    userData: function () {
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    $route (to, from) {
      this.currentRoute = this.$route.name
      this.routeMetaText = this.$route.meta.text
    }
  },
  methods: {
    getJobs () {
      this.$store.dispatch('reposession/getJobData', this.userData)
    },
    logout: function () {
      this.$store.dispatch('auth/logout')
    },
    back: function () {
      const prevRouteName =
        this.$store.getters['reposession/dashboardGetter']('prevRoute').name
      const prevRoute =
        this.$store.getters['reposession/dashboardGetter'](
          'prevRoute'
        ).fullPath
      const currentRoute = this.$router.history.current.name
      if (prevRouteName === 'cardList' && currentRoute === 'card') {
        this.$router.replace({ path: prevRoute })
      } else if (
        prevRouteName === 'card' &&
        (currentRoute === 'add_notes' ||
          currentRoute === 'images' ||
          currentRoute === 'reassign' ||
          currentRoute === 'update_note')
      ) {
        this.$router.replace({ path: prevRoute })
      } else if (prevRouteName === 'main' && currentRoute === 'cardList') {
        this.$router.replace({ path: prevRoute })
      } else {
        this.$router.replace({ name: 'main' })
      }
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scroll = top > 20
      const st = window.pageYOffset || document.body.scrollTop
      if (st > this.lastScrollTop) {
        this.scrollDirection = 0
      } else {
        this.scrollDirection = 1
      }
      this.lastScrollTop = st <= 0 ? 0 : st
    },
    scrollXY () {
      this.scrollDirection === 0 ? this.$vuetify.goTo(0) : this.$vuetify.goTo(9999, { duration: 800, offset: 0, easing: 'easeInOutCubic' })
    }
  }
}
</script>

<style>
  .scroll--btn {
    position: fixed;
    bottom: 10%;
    align-items: center;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 0);
  }
</style>
