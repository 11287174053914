<template>
    <div style="position: relative;" class="mt-3 mx-2">
        <v-card class="my-2 cardStyle" flat>
         <v-card-title>Add Note</v-card-title>
         <v-card-text>
           <v-text-field filled rows="1" label="Note Description" v-model="description"></v-text-field>
           <div>Note Body</div>
           <v-textarea rows="5" v-model="Note" filled clearable></v-textarea>
           <!-- <NoteEditor v-model="Note" :content="Note" /> -->
           <div class="d-flex justify-center my-3">
              <v-btn @click="isEdit ? updateNotesPost(documentNo) : saveNotes(documentNo)" color="primary" class="btnActions">
                  <v-icon class="mx-2">save</v-icon>
                  {{ isEdit ? 'Update' : 'Save' }}
              </v-btn>
          </div>
         </v-card-text>
        </v-card>
        <div style="margin-bottom: 20%;"></div>
    </div>
</template>
<style>
    @import url('../styles.css');
</style>
<script>
// import NoteEditor from './NoteEditor'
import RepoMethods from '../RepoMethods'
export default {
  name: 'AddNotes',
  components: { /** NoteEditor**/ },
  data: () => ({
    Note: '',
    description: '',
    isEdit: false
  }),
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.getJob(atob(v.$route.params.document_no))
      if (v.job !== undefined) {
        v.getNotesUpdate(to.query.line_no, v.documentNo)
      }
    })
  },
  watch: {
    job () {
      if (Object.keys(this.job).length !== 0) {
        this.getNotesUpdate(this.$route.query.line_no, this.documentNo)
      }
    }
  },
  computed: {
    documentNo: function () {
      return atob(this.$route.params.document_no)
    },
    job: function () {
      return this.$store.getters['reposession/getJobObject']()
    }
  },
  methods: {
    ...RepoMethods
  }
}
</script>
