import helpers from '../../../../cetus-base/utilities/helpers/helper'
class ProclamationMethods {
  methods () {
    return {
      logout: function () {
        this.$store.dispatch('auth/logout')
      },
      make_proclamation (event) {
        this.formData.amount_to_be_collected = parseFloat(this.formData.amount_to_be_collected)
        !this.isValid
          ? this.$refs.procform.validate()
          : this.$store.dispatch('proclamation/makeProclamation', this.formData)
        event.target.reset()
      },
      selectBusiness () {
        if (this.formData.business_source_code.length !== 0) {
          this.formData.business_source_name = this.businessSources.filter((b) => b.business_code === this.formData.business_source_code)[0].business_code_name
        }
      },
      selectCustomer () {
        if (this.formData.customer_no.length !== 0) {
          this.formData.customer_name = this.customers.filter((b) => b.customer_no === this.formData.customer_no)[0].customer_name
        }
      },
      onButtonClick: function () {
        !this.isValid
          ? this.$refs.imageForm.validate()
          : this.$refs.uploader.click()
      },
      attachingDocuments (formData) {
        const saveAttachment = helpers.prepareFormData(formData)
        saveAttachment.code = formData.document_no
        this.$store.dispatch('proclamation/attachingDocuments', saveAttachment)
      },
      upload (code, type) {
        this.formData.document_no = code
        this.formData.attachment = this.$refs.uploader.files[0]
        this.formData.type = type
        this.formData.document_type = this.documentType
        this.imageDialog = false
        this.attachingDocuments(this.formData)
      }
    }
  }
}

export default new ProclamationMethods().methods()
