import call from '../../cetus-base/service'
import constants from './constants'

export default {
  namespaced: true,
  state: {
    storage: [],
    storageCharges: [],
    storageCard: {},
    searchDialog: false,
    valuationData: {},
    yards: []
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data
    }
  },
  getters: {
    storageGetters: (state) => (val) => state[val],
    storageByStatus: (state) => (val) => val ? state.storage.filter(i => i.status === val) : state.storage
  },
  actions: {
    setSearchDialog: ({ commit }, data) => {
      commit('MUTATE', { state: 'searchDialog', data })
    },
    getStorageItems: ({ commit }) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.storage).then((res) => {
        commit('MUTATE', { state: 'storage', data: res.data.data })
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    getYards: ({ commit }) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.yards).then((res) => {
        commit('MUTATE', { state: 'yards', data: res.data.data })
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    storageCharges: ({ commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.storageCharges).then((res) => {
        commit('MUTATE', { state: 'storageCharges', data: res.data.data })
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    getStorageCard: ({ commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.storageCard(data)).then((res) => {
        commit('MUTATE', { state: 'storageCard', data: res.data.data[0] })
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    submitStorage: ({ commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.storage, data).then((res) => {
        Event.$emit('ApiSuccess', `${res.data.message}, Submitted Storage Card`)
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('submitStorageCard', res.data.data[0])
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    updateStatus: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('patch', constants.updateStatus(data), data).then((res) => {
        Event.$emit('ApiSuccess', res.data.data.message)
        dispatch('getStorageCard', res.data.data[0].no)
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    fetchValuation: ({ commit }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('get', constants.valuation(data)).then((res) => {
        commit('MUTATE', { state: 'valuationData', data: res.data.data })
        commit('loader/SET_LOADING', false, { root: true })
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    updateStorage: ({ commit, dispatch }, data) => {
      delete data.customerNo
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.update, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        dispatch('getStorageCard', res.data.data.shift().no)
        Event.$emit('updateState')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    addStorageLines: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.addCharges, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        dispatch('getStorageCard', res.data.data[0].documentNo)
        Event.$emit('ApiSuccess', 'Successfully added charges')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    book: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.book, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        dispatch('getStorageCard', data.storageNo)
        Event.$emit('ApiSuccess', 'Successfully Booked Storage Card')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    checkout: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.checkout, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        dispatch('getStorageCard', data.storageNo)
        Event.$emit('ApiSuccess', 'Successfully Checked Out Storage Card')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    },
    close: ({ commit, dispatch }, data) => {
      commit('loader/SET_LOADING', true, { root: true })
      call('post', constants.close, data).then((res) => {
        commit('loader/SET_LOADING', false, { root: true })
        dispatch('getStorageCard', data.storageNo)
        Event.$emit('ApiSuccess', 'Successfully Closed Storage Card')
      }).catch((error) => {
        commit('loader/SET_LOADING', false, { root: true })
        Event.$emit('ApiError', error.response.data.message)
      })
    }
  }
}
