/* eslint-disable camelcase */
import helpers from '../../../../cetus-base/utilities/helpers/helper'
import moment from 'moment'
class RepoMethods {
  methods () {
    return {
      getTimeDateFormat: function (date) {
        return moment(date).format('Do ddd, MMM YYYY hh:mm:ss a')
      },
      getDateFormat: function (date) {
        return moment(date).format('Do dddd, MMM YYYY')
      },
      mergeAttachmentsTypes: function () {
        const attachments = {}
        this.attachmentTypes.map((type) => {
          attachments[type.description] = this.getAttachmentByType(type.description)
        })
        return attachments
      },
      getAttachmentByType: function (type) {
        return this.job.repossesionAttachment !== undefined
          ? this.job.repossesionAttachment.filter((att) => att.attachment_type === type)
          : []
      },
      getAgent: function () {
        return this.$store.getters['reposession/getAgentAuth'](this.userData.agent_code)
      },
      getJobs () {
        this.$store.dispatch('reposession/getJobData', this.userData)
      },
      getJob (documentNo) {
        this.$store.dispatch('reposession/getJobCard', documentNo)
      },
      agentName (agent) {
        return this.agents.length !== 0
          ? this.$store.getters['reposession/dashboardGetter']('agents').filter((a) => a.code === agent.agent_code)[0].name
          : ''
      },
      showAssignedAgent (job) {
        return this.agents.length !== 0 && job.agent_code !== ''
          ? this.agents.filter((a) => a.code === job.agent_code)
          : ''
      },
      reassignJobCard (job) {
        /** change all assigned to Archived */
        job.assignedAgents.map((agent) => {
          agent.status = 'Archived'
        })
        /** add newly assigned agent to the list as active */
        this.reassignedFormData.document_no = job.no
        this.reassignedFormData.type = 'Agent'
        const data = {
          assigned: job.assignedAgents,
          reassigned: this.reassignedFormData,
          type: 0
        }
        this.$store.dispatch('reposession/reassignJobCard', data)
      },
      recallJobCard (job) {
        const data = {
          assigned: job.assignedAgents.filter((agent) => {
            if (agent.agent_code !== this.userData.agent_code) {
              agent.status = 'Archived'
              return agent
            }
          }),
          reassigned: job.assignedAgents.filter((agent) => {
            if (agent.agent_code === this.userData.agent_code) {
              agent.status = 'Active'
              delete agent.line_no
              delete agent.response
              agent.recalled_by = this.userData.agent_code
              agent.recalled_from = job.agent_code
              agent.recalled_date = moment(new Date()).format('YYYY-MM-DD')
              return agent
            }
          })[0],
          type: 1
        }
        Event.$emit('comments', {
          message: 'Enter recall reason?',
          callbackInfo: 'Recall Action',
          action: 'reassignJobCard',
          payload: data
        })
      },
      startJobs (job) {
        job.repossesionAttachment = []
        Event.$emit('confirm', {
          message: 'Do you want to accept this job',
          callbackInfo: 'Job Accepted',
          action: 'startJobs',
          payload: job
        })
      },
      finishJobs (job) {
        job.repossesionAttachment = []
        Event.$emit('confirm', {
          message: 'Do you want to finnish this job',
          callbackInfo: 'Job Finished',
          action: 'finishJobs',
          payload: job
        })
      },
      submitJob (job) {
        job.repossesionAttachment = []
        Event.$emit('confirm', {
          message: 'Do you want to Submit this job',
          callbackInfo: 'Job Submitted',
          action: 'submitJobs',
          payload: job
        })
      },
      attachingDocuments (formData) {
        const saveAttachment = helpers.prepareFormData(formData)
        saveAttachment.code = formData.document_no
        this.$store.dispatch('reposession/attachingDocuments', saveAttachment)
      },
      logout: function () {
        this.$store.dispatch('auth/logout')
      },
      back: function () {
        const prevRouteName = this.$store.getters['reposession/dashboardGetter']('prevRoute').name
        const prevRoute = this.$store.getters['reposession/dashboardGetter']('prevRoute').fullPath
        const currentRoute = this.$router.history.current.name
        if (prevRouteName === 'cardList' && currentRoute === 'card') {
          this.$router.replace({ path: prevRoute })
        } else if (prevRouteName === 'card' && (currentRoute === 'add_notes' || currentRoute === 'images' || currentRoute === 'reassign' || currentRoute === 'update_note')) {
          this.$router.replace({ path: prevRoute })
        } else if (prevRouteName === 'main' && currentRoute === 'cardList') {
          this.$router.replace({ path: prevRoute })
        } else {
          this.$router.replace({ name: 'main' })
        }
      },
      viewCards: function (item) {
        this.$router.replace({ path: 'card_list', query: { status: item.status } })
      },
      action: function (path, data) {
        this.$router.replace({ name: path, params: { jobCode: btoa(data.jobCode) }, query: { line_no: data.line_no } })
      },
      addNotes: function (document_no) {
        this.$router.replace({ name: 'add_notes', params: { document_no: btoa(document_no) } })
      },
      updateNotes: function (document_no, line_no) {
        this.$router.replace({ name: 'add_notes', params: { document_no: btoa(document_no) }, query: { line_no } })
      },
      onButtonClick: function () {
        !this.isValid
          ? this.$refs.imageForm.validate()
          : this.$refs.uploader.click()
      },
      submit: function () {
        this.$store.dispatch('saveDetails', this.formData)
      },
      upload (code, type) {
        this.formData.document_no = code
        this.formData.attachment = this.$refs.uploader.files[0]
        this.formData.type = type
        this.imageDialog = false
        this.attachingDocuments(this.formData)
      },
      viewCard: function (code) {
        this.$router.replace({ name: 'card', params: { jobCode: btoa(code) } })
      },
      saveNotes (document_no) {
        const data = {
          document_no,
          description: this.description,
          notes: this.Note,
          line_no: 0,
          update_code: 0
        }
        this.$store.dispatch('reposession/postNotes', data)
      },
      getNotes: function (attachments) {
        return this.$store.getters['reposession/getNotes'](attachments)
      },
      getPhotos: function (attachments, type) {
        return this.$store.getters['reposession/getPhotos'](attachments, type)
      },
      removeAttachments: function () {
        const attachment = this.getPhotos(this.job.repossesionAttachment, 'All')[this.imageOnView]
        Event.$emit('confirm', {
          message: 'Do you want to delete this attachment?',
          callbackInfo: 'Attachment Deleted',
          action: 'removeAttachments',
          payload: attachment
        })
      },
      removeNotes: function (note) {
        Event.$emit('confirm', {
          message: 'Do you want to delete this note?',
          callbackInfo: 'Note Deleted',
          action: 'removeAttachments',
          payload: note
        })
      },
      getNotesUpdate: function (line_no, document_no) {
        if (line_no !== undefined) {
          const note = this.job.repossesionAttachment.filter((repo) => repo.line_no === parseInt(line_no))[0]
          this.Note = this.notesDecoder(note.attachment)
          this.description = note.description
          this.isEdit = true
        }
      },
      updateNotesPost: function (document_no) {
        const data = {
          notes: this.Note,
          description: this.description,
          document_no,
          line_no: this.$route.query.line_no,
          update_code: 1
        }
        this.$store.dispatch('reposession/postNotes', data)
      },
      notesDecoder: function (attachment) {
        return atob(attachment)
      },
      reassignRoute: function (jobCode) {
        this.$router.replace({ name: 'reassign', params: { jobCode: btoa(jobCode) } })
      },
      getLocation: function (code) {
        this.$store.dispatch('reposession/getLocation', code)
      },
      isPdf: function (value) {
        if (!value) return false
        const buffer = Buffer.from(value, 'base64')
        const bufString = buffer.toString('hex')
        return bufString.slice(0, 10) === '255044462d'
      },
      pageCount: function (value) {
        const bin = atob(value)
        return bin.match(/\/Type\s*\/Page\b/g).length
      },
      requestCash: function () {
        this.$router.replace({ name: 'RequestCash', params: { jobCode: btoa(this.job.no) } })
      }
    }
  }
}

export default new RepoMethods().methods()
