<template>
  <v-container class="mb-10 pb-10">
    <div
      class="mt-2 mx-2"
      :style="{ filter: scan && isSubmit && !isEdit ? 'blur(2.5px)' : '' }"
    >
      <v-card class="my-2 cardStyle" flat>
        <v-card v-if="!isSubmit" class="d-flex" flat>
          <v-card class="mr-auto text-h6" flat>
            <div class="ml-1">
              <v-list dense color="transparent">
                <v-list-item dense>
                  <v-list-item-action>
                    <v-avatar color="blue lighten-1" style="color: #fff">{{
                      storageCard.agentCode
                    }}</v-avatar>
                  </v-list-item-action>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title>{{
                      storageCard.agentCode
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      `${storageCard.fullName}`
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
          <v-card class="px-2 py-2 d-flex justify-end flex-md-wrap" flat>
            <v-btn
              color="cyan darken-1"
              class="white--text btnActions"
              @click="update('book')"
              v-if="storageCard.status === 'New'"
            >
              <v-icon class="mx-1">beenhere</v-icon>
              Book
            </v-btn>
            <v-btn
              color="green darken-1"
              class="white--text btnActions"
              @click="update('checkout')"
              v-if="storageCard.status === 'Booked'"
            >
              <v-icon class="mx-1">check</v-icon>
              Checkout
            </v-btn>
            <v-btn
              color="teal darken-1"
              class="white--text btnActions"
              @click="update('close')"
              v-if="storageCard.status === 'Check Out'"
            >
              <v-icon class="mx-1">check</v-icon>
              Close
            </v-btn>
          </v-card>
        </v-card>
        <v-list dense class="mt-n5">
            <v-list-item dense v-if="!scan || !isSubmit || isEdit">
              <v-list-item-content>
                <v-card
                  label
                  flat
                  style="font-weight: bold; font-size: 17px"
                  class="d-flex flex-row"
                >
                  <v-img
                    :src="`data:image/png;base64,${isSubmit && !isEdit ? scanned.barcode : storageCard.barcode}`"
                    :height="isSubmit && !isEdit ? '100px' : '100%'"
                    :width="isSubmit && !isEdit ? '100px' : '100%'"
                  ></v-img>
                  <div v-if="isSubmit" style="width: 100px; height: 100px">
                    <v-img :src="`data:image/png;base64,${scanned.qr_code}`"></v-img>
                  </div>
                </v-card>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isSubmit">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-space-between">
                  <div class="text-h6 mt-1">
                    Status
                  </div>
                  <v-btn
                    color="blue darken-1"
                    class="white--text btnActions mb-2"
                    @click="edit"
                    v-if="storageCard.status === 'New'"
                  >
                    <v-icon class="mx-1">edit</v-icon>
                    Edit
                  </v-btn>
                </v-list-item-title
                >
                <v-chip
                  :color="statusColor(storageCard.status)"
                  label
                  style="font-weight: bold; font-size: 17px"
                  >{{ storageCard.status }}</v-chip
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        <StorageForm :isSubmit="isSubmit" :isEdit="isEdit" :scan="scan" @displayScanned="displayScanned" />
      </v-card>
    </div>
    <v-overlay v-if="scan && isSubmit && !isEdit" class="scanner--overlay" opacity="0.2"></v-overlay>
  </v-container>
</template>
<script>
import helpers from '../../../cetus-base/utilities/helpers/helper.js'
import StorageForm from './StorageForm.vue'
export default {
  name: 'StorageCard',
  components: { StorageForm },
  data: function () {
    return {
      isSubmit: false,
      isEdit: false,
      scan: true,
      scanned: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      if (to.query.no) {
        v.$store.dispatch('storage/getStorageCard', to.query.no)
        v.isSubmit = false
      } else {
        v.isSubmit = true
      }
      v.$store.dispatch('storage/storageCharges')
      v.$store.dispatch('storage/getYards')
    })
  },
  mounted () {
    Event.$on('submitStorageCard', ({ no }) => {
      this.$router.replace({ name: 'StorageCard', query: { no } })
      this.$router.go()
    })
    Event.$on('updateState', () => {
      this.isSubmit = false
      this.isEdit = false
    })
  },
  computed: {
    storageCard: function () {
      return this.$store.getters['storage/storageGetters']('storageCard')
    }
  },
  methods: {
    statusColor: function (status) {
      return helpers.getStatusColor(status)
    },
    displayScanned: function (val) {
      this.scan = val.scan
      delete val.scan
      this.scanned = val
    },
    update: function (action) {
      this.$confirm.show({
        title: 'Status Update',
        text: `Do you want to ${action} this storage item?`,
        onConfirm: () => {
          this.$store.dispatch(`storage/${action}`, { storageNo: this.storageCard.no })
        }
      })
    },
    edit: function () {
      this.isEdit = true
      this.isSubmit = true
    }
  }
}
</script>
<style>
  .scanner--overlay {
    height: calc(100% - 120px);
    max-width: 30%;
    margin-left: 35%;
    margin-top: 64px;
  }
  @media only screen and (max-width: 768px) {
    .scanner--overlay {
      height: calc(100% - 112px);
      max-width: 100%;
      margin-left: 0%;
      margin-top: 56px;
    }
  }
</style>
