import { DashboardLayout } from '../../../../cetus-base/modules/dashboard'
import Main from './views/Main'
import CardList from './components/CardList'
import Card from './components/Card'
import AddNotes from './components/AddNotes'
import ImageCard from './components/ImageCard'
import Reassign from './components/Reassign'
import RequestCash from '@/packages/cetus-repossession/src/modules/repossession/components/RequestCash'
import Auth from '../../../../../router/middleware/Auth'

export default [
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '',
        redirect: '/dashboard/main'
      },
      {
        path: 'main',
        name: 'main',
        component: Main,
        meta: {
          middleware: Auth,
          text: 'Main Dashboard'
        }
      },
      {
        path: 'card_list',
        name: 'cardList',
        component: CardList,
        meta: {
          middleware: Auth,
          text: 'Card List'
        }
      },
      {
        path: 'card/:jobCode',
        name: 'card',
        component: Card,
        meta: {
          middleware: Auth,
          text: 'Repossession Card'
        }
      },
      {
        path: 'add_notes/:document_no',
        name: 'add_notes',
        component: AddNotes,
        meta: {
          middleware: Auth,
          text: 'Add Notes'
        }
      },
      {
        path: 'update_note/:document_no',
        name: 'update_note',
        component: AddNotes,
        meta: {
          middleware: Auth,
          text: 'Update Note'
        }
      },
      {
        path: 'reassign/:jobCode',
        name: 'reassign',
        component: Reassign,
        meta: {
          middleware: Auth,
          text: 'Reassign Job Card'
        }
      },
      {
        path: 'images/:jobCode',
        name: 'images',
        component: ImageCard,
        meta: {
          middleware: Auth,
          text: 'Photos Taken'
        }
      },
      {
        path: 'RequestCash/:jobCode',
        name: 'RequestCash',
        component: RequestCash,
        meta: {
          middleware: Auth,
          text: 'Request Cash'
        }
      }
    ]
  }
]
