<template>
<v-overlay absolute color="#EAF8F1">
    <fulfilling-bouncing-circle-spinner
      :animation-duration="3000"
      :size="60"
      color="#0A9971"
    />
</v-overlay>
</template>

<script>
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
export default {
  name: 'Spinner',
  components: {
    FulfillingBouncingCircleSpinner
  }
}
</script>

<style scoped></style>
