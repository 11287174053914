<template>
  <v-app id="inspire" style="background: #000;">
    <v-main class="appBack">
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
.appBack {
  background: #F7F6FE;
  height: 100%;
  width: 30%;
  margin-left: 35%;
}
@media only screen and (max-width: 768px) {
  .appBack {
    background: #F7F6FE;
    height: 100%;
    width: 100%;
    margin-left: 0;
  }
}
</style>
<script>
export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  })
}
</script>
