<template>
    <div class="margin-bottom: 25%;">
       <div v-if="getJobByStatus.length === 0" style="margin-top: 50%;" class="d-flex justify-center">
        <div>
            <v-icon size="100">error</v-icon>
            <div class="my-3 ml-3 text-caption">Nothing Here</div>
        </div>
       </div>
       <div v-else style="margin-bottom: 20%;" class="mt-4 mx-2">
            <v-card class="cardStyle mb-7" flat style="cursor: pointer;" v-for="(item, i) in getJobByStatus" :key="i" @click="viewCard(item.no)">
                <div>
                    <v-list-item-avatar class="mx-1" color="blue lighten-1" style="color: #fff;">{{ item.agent_code }}</v-list-item-avatar>
                    <v-chip class="mx-1" label>Doc No: {{ item.no }}</v-chip>
                    <v-chip style="position: absolute; top: 1em; right: 0.5em;" :color="item.statusColor" label>{{ item.document_status }}</v-chip>
                        <v-list dense>
                        <v-list-item dense v-if="item.document_status === 'Canceled'">
                            <v-list-item-content>
                                <v-list-item-title style="font-size: 15px;">Canceling Reason</v-list-item-title>
                                <v-card class="my-2 cardStyle" style="background: #e0e0e0;" flat>
                                    <v-card-text>
                                    <div class="commentText">{{ item.canceling_reason_code }}</div>
                                    <div class="commentText">{{ `comment: ${item.canceling_comment}` }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                            <v-list-item-content>
                            <v-list-item-title style="font-size: 15px;">Customer Name</v-list-item-title>
                            <v-chip  label style="font-weight: bold; font-size: 17px;">{{ item.name }}</v-chip>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                            <v-list-item-content>
                            <v-list-item-title style="font-size: 15px;">Vehicle Registration Number</v-list-item-title>
                            <v-chip  label style="font-weight: bold; font-size: 17px;">{{ item.vehicle_registration_no }}</v-chip>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense v-if="item.document_status !== 'Released'">
                            <v-list-item-content>
                            <v-list-item-title style="font-size: 15px;">Date Received</v-list-item-title>
                            <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(item.date_received) }}</v-chip>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense v-if="item.document_status === 'Finished'">
                            <v-list-item-content>
                            <v-list-item-title style="font-size: 15px;">Closing Date</v-list-item-title>
                            <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(item.closing_date) }}</v-chip>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item dense v-if="item.document_status === 'Cancelled'">
                            <v-list-item-content>
                            <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(item.cancelled_info) }}</v-chip>
                            </v-list-item-content>
                        </v-list-item> -->
                        <!-- <v-list-item dense>
                            <v-list-item-content>
                            <v-list-item-title style="font-size: 15px;">Posting Date</v-list-item-title>
                            <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(item.posting_date) }}</v-chip>
                            </v-list-item-content>
                        </v-list-item> -->
                    </v-list>
                </div>
            </v-card>
        </div>
        <v-fab-transition>
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        fixed
                        bottom
                        right
                        fab
                        v-bind="attrs"
                        v-on="on"
                        @click="searchDialog = !searchDialog"
                        class="buttons"
                    >
                        <v-icon> {{ searchDialog ? 'mdi-close' : 'search' }} </v-icon>
                    </v-btn>
                </template>
                <span>Search jobs</span>
            </v-tooltip>
        </v-fab-transition>
        <Search :search="search" @searchInput="updateSearch" />
    </div>
</template>
<style>
    @import url('../styles.css');
</style>
<script>
import RepoMethods from '../RepoMethods'
import Search from '../../../../../cetus-base/components/Search.vue'
export default {
  name: 'CardList',
  components: { Search },
  data: function () {
    return {
      search: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.$store.dispatch('reposession/updatePrevRoute', from)
    })
  },
  computed: {
    getJobByStatus: function () {
      const { status } = this.$route.query
      return this.search !== ''
        ? this.$store.getters['reposession/getJobByStatus'](status).filter((item, i) =>
          Object.keys(item).filter((key) =>
            item[key].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1).length !== 0)
        : this.$store.getters['reposession/getJobByStatus'](status)
    },
    searchDialog: {
      set (value) {
        this.$store.dispatch('reposession/setSearchDialog', value)
      },
      get () {
        return this.$store.getters['reposession/getSearchDialog']
      }
    }
  },
  methods: {
    ...RepoMethods,
    updateSearch (val) {
      this.search = val
    }
  }
}
</script>
