<template>
      <div style="position: relative;" class="mt-4 mx-2" v-if="job !== undefined">
            <v-card class="my-2 cardStyle" flat>
                <v-card class="d-flex" flat>
                  <v-card class="mr-auto my-2 mx-2 text-h6" flat>
                    Job Card
                  </v-card>
                  <v-card class="px-2 py-2 d-flex justify-end" flat>
                    <v-btn v-if="job.document_status === 'Released'" color="warning" class="btnActions" @click="startJobs(job)">
                        <v-icon class="mx-2">check</v-icon>
                        Accept
                    </v-btn>
                    <v-btn v-if="job.document_status === 'In Progress' && !getAgent().is_coordinator" color="teal" class="btnActions" @click="submitJob(job)">
                        <v-icon class="mx-2" color="#fff">check</v-icon>
                        <div style="color: #fff">Submit</div>
                    </v-btn>
                    <v-btn v-if="job.document_status === 'Submitted' && getAgent().is_coordinator" color="primary" class="btnActions" @click="finishJobs(job)">
                        <v-icon class="mx-2">check</v-icon>
                        Finnish
                    </v-btn>
                  </v-card>
                </v-card>
                <v-card class="d-flex" flat>
                  <v-card class="mr-auto text-h6" flat>
                    <div class="ml-1 mt-3">
                      <v-avatar color="blue lighten-1" style="color: #fff;">{{ job.agent_code }}</v-avatar>
                    </div>
                  </v-card>
                  <v-card class="py-2 d-flex justify-end" flat v-if="job.document_status !== 'Canceled' && job.document_status !== 'Submitted'">
                    <v-card-text>
                      <v-btn v-if="job.document_status !== 'Finished' && getAgent().is_coordinator && job.agent_code !== '' && job.agent_code !== this.userData.agent_code" color="warning" class="mr-1 btnActions" link @click="recallJobCard(job)">
                        <v-icon left>call_to_action</v-icon>
                        Recall
                      </v-btn>
                      <v-btn v-if="job.document_status !== 'Finished' && getAgent().is_coordinator" color="info" class="ml-1 btnActions" link @click="reassignRoute(job.no)">
                        <v-icon left>assignment</v-icon>
                        Reassign
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-card>
                  <v-list dense>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Document Status</v-list-item-title>
                        <v-chip :color="job.statusColor" label style="font-weight: bold; font-size: 17px;">{{ job.document_status }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense v-if="job.document_status === 'Canceled'">
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Canceling Reason</v-list-item-title>
                        <v-card class="my-2 cardStyle" style="background: #e0e0e0;" flat>
                            <v-card-text>
                              <div class="commentText">{{ job.canceling_reason_code }}</div>
                              <div class="commentText">{{ `comment: ${job.canceling_comment}` }}</div>
                            </v-card-text>
                        </v-card>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Document Number</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;"> {{ job.no }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Assigned Agent</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;"> {{ job.agent_code }} -
                          <span v-for="(item, i) in showAssignedAgent(job)" :key="i">{{ item.name }}</span>
                        </v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Customer Name</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;">{{ job.name }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Vehicle Registration Number</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;">{{ job.vehicle_registration_no }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Business Source Name</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;"> {{ job.business_source_name }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Document Type</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;"> {{ job.document_type }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Amount To Be Collected</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;"> {{ job.amount_to_be_collected }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense v-if="job.location !== ''">
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Location</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;">
                          {{ `${ location.code} - ${ location.description }` }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense v-if="job.document_status !== 'Released'">
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Date Received</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(job.date_received) }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense v-if="job.document_status === 'Finished'">
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Closing Date</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(job.closing_date) }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px;">Posting Date</v-list-item-title>
                        <v-chip  label style="font-weight: bold; font-size: 17px;">{{ getDateFormat(job.posting_date) }}</v-chip>
                      </v-list-item-content>
                    </v-list-item> -->
                  </v-list>
            </v-card>
            <v-card class="my-2 cardStyle" flat>
              <v-card-text>
                <v-btn color="primary" block class="text-capitalize" large @click="requestCash" link>
                  click To Request Cash
                  <v-icon class="mx-2">payments</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card class="my-2 cardStyle" flat>
                <v-card-title>All Photos</v-card-title>
                <v-card-text v-if="job !== undefined">
                  <div class="d-inline-flex flex-wrap justify-start" v-if="getPhotos(job.repossesionAttachment, 'All').length !== 0">

                    <div
                      v-for="(item, i) in getPhotos(job.repossesionAttachment, 'All')"
                      :key="i"
                      @click="action('images', { jobCode: job.no, line_no: item.line_no })"
                    >

                      <pdf
                        class="mx-1 my-1"
                        style="height: 100px; width: 100px;"
                        v-if="isPdf(item.attachment)"
                        :src="`data:application/pdf;base64,${item.attachment}`"
                      />

                      <v-img
                        v-else
                        lazy-src="../../../../assets/loading.gif"
                        :src="`data:image/png;base64,${ item.attachment }`"
                        class="mx-1 my-1"
                        style="height: 100px; width: 100px;"
                      />

                    </div>

                  </div>
                  <div v-else>
                    <v-btn
                      color="primary"
                      dark
                      block
                      outlined
                      @click="imageDialog = true/**onButtonClick**/"
                      v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')">
                        <v-icon class="mx-3">mdi-image-plus</v-icon>
                        Add/Take Photos
                    </v-btn>
                  </div>
                </v-card-text>
            </v-card>
            <v-card class="my-2 cardStyle" flat>
                <v-card-title>Notes</v-card-title>
                <v-card-text v-if="job !== undefined">
                  <div v-if="getNotes(job.repossesionAttachment).length !== 0">
                    <div v-for="(item, i) in getNotes(job.repossesionAttachment)" :key="i" class="my-3">
                      <div class="text-h6 font-weight-black">{{ getTimeDateFormat(item.created_at) }}</div>
                        <div>
                          <v-card rounded elevation="0" color="rgb(224, 224, 224)" class="px-2 py-2">
                            <v-card-title>{{ item.description }}</v-card-title>
                            <v-card-text v-html="notesDecoder(item.attachment)"></v-card-text>
                            <v-card-actions v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')">
                                    <v-btn small color="error" @click="removeNotes(item)" class="btnActions">
                                      <v-icon class="mx-2">delete</v-icon>
                                        Delete
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn small color="primary" @click="updateNotes(item.document_no, item.line_no)" class="btnActions">
                                      <v-icon class="mx-2">update</v-icon>
                                        Edit
                                    </v-btn>
                            </v-card-actions>
                          </v-card>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn
                      color="primary"
                      dark
                      block
                      outlined
                      @click="addNotes(job.no)"
                      v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')">
                      <v-icon class="mx-3">note_add</v-icon>
                      Add Notes
                    </v-btn>
                  </div>
                </v-card-text>
                <!-- <v-chip @click="addNotes(job.no)" color="light-blue lighten-1" small label style="position: absolute; top: 5%; right: 3%;">
                    edit
                </v-chip> -->
            </v-card>
            <v-card v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')" class="my-2 cardStyle" flat>
                <v-card-title>Repossession Checklist</v-card-title>
                <v-card-text>
                    <v-btn
                      color="primary"
                      dark
                      block
                      outlined
                      @click="imageDialog = true/**onButtonClick**/">
                        <v-icon class="mx-3">mdi-image-plus</v-icon>
                        Add/Take Photos
                    </v-btn>
                </v-card-text>
            </v-card>
            <v-card class="my-2 cardStyle" flat>
              <v-card-title>Select Payment</v-card-title>
              <v-card-text>
                <v-btn-toggle
                  v-model="selectedType"
                  style="width: 100%;"
                  color="primary"
                  width>
                  <v-btn color="primary" outlined style="width: 50%;" value="left" class="px-10">
                    <v-icon left>
                      credit_card
                    </v-icon>
                    <span class="caption">Paid</span>
                  </v-btn>
                  <v-btn color="primary" outlined style="width: 50%;" value="right" class="px-10">
                    <v-icon left>
                     money_off
                    </v-icon>
                    <span class="caption">Not Paid</span>
                  </v-btn>
                </v-btn-toggle>
              </v-card-text>
            </v-card>
            <v-card v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')" v-show="selectedType === 'left'" class="my-2 cardStyle" flat>
                <v-card-title>Payment Attachament</v-card-title>
                <v-card-text>
                    <v-btn
                      color="primary"
                      dark
                      block
                      outlined
                      @click="imageDialog = true/**onButtonClick**/">
                        <v-icon class="mx-3">mdi-image-plus</v-icon>
                        Add/Take Photos
                    </v-btn>
                </v-card-text>
            </v-card>
            <v-card v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')" v-show="selectedType === 'right'" class="my-2 cardStyle" flat>
                <v-card-title>Notification For Sale</v-card-title>
                <v-card-text>
                    <v-btn
                      color="primary"
                      dark
                      block
                      outlined
                      @click="imageDialog = true/**onButtonClick**/">
                        <v-icon class="mx-3">mdi-image-plus</v-icon>
                        Add/Take Photos
                    </v-btn>
                </v-card-text>
            </v-card>
            <div class="text-h6">Attachment Types</div>
            <v-card v-for="(attachment, type) in mergeAttachmentsTypes()" :key="type" class="my-2 cardStyle" flat>
                <v-card-title>{{ type }}</v-card-title>
                <v-card-text>
                  <div class="d-inline-flex flex-wrap justify-start" v-if="getPhotos(attachment, type).length !== 0">

                    <div v-for="(item, i) in getPhotos(attachment, type)" :key="i" @click="action('images', { jobCode: job.no, line_no: item.line_no })">
                      <pdf
                        style='width: 100%'
                        v-if="isPdf(item.attachment)"
                        :src="`data:application/pdf;base64,${item.attachment}`"
                      />

                      <v-img
                        v-else
                        lazy-src="../../../../assets/loading.gif"
                        :src="`data:image/png;base64,${ item.attachment }`"
                        class="mx-1 my-1" style="height: 100px; width: 100px;"
                      />
                    </div>
                  </div>
                </v-card-text>
            </v-card>
            <v-card class="my-2 cardStyle" flat>
                <v-card-title>Comments</v-card-title>
                <v-card-text>
                  <div>{{ job.comments }}</div>
                </v-card-text>
            </v-card>
            <div style="margin-bottom: 25%;"></div>
            <v-slide-y-reverse-transition v-if="showMenu" group>
                <div :key="1">
                    <v-fab-transition>
                        <v-btn
                            color="primary"
                            dark
                            fixed
                            bottom
                            right
                            fab
                            class="buttons2"
                            @click="addNotes(job.no)"
                        >
                            <v-icon>note_add</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </div>
                <div :key="2">
                    <v-fab-transition>
                        <v-btn
                            color="primary"
                            dark
                            fixed
                            bottom
                            right
                            fab
                            @click="imageDialog = true/**onButtonClick**/"
                            class="buttons1"
                        >
                            <v-icon>mdi-image-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </div>
            </v-slide-y-reverse-transition>
            <v-fab-transition v-if="job.document_status === 'In Progress' || (getAgent().is_coordinator && job.document_status === 'Submitted')">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            fixed
                            bottom
                            right
                            fab
                            v-bind="attrs"
                            v-on="on"
                            @click="showMenu = !showMenu"
                            class="buttons"
                        >
                            <v-icon> {{ showMenu ? 'mdi-close' : 'mdi-plus' }} </v-icon>
                        </v-btn>
                    </template>
                    <span>Add photos/notes</span>
                </v-tooltip>
                </v-fab-transition>
            <ImageModal :job="job" />
        </div>
</template>
<style>
 @import url('../styles.css');
</style>
<script>
import RepoMethods from '../RepoMethods'
import ImageModal from './ImageModal'
import pdf from 'vue-pdf'
export default {
  name: 'Card',
  components: { ImageModal, pdf },
  data: () => ({
    showMenu: false,
    selectedType: null,
    formData: {
      comments: ''
    }
  }),
  beforeRouteEnter (to, from, next) {
    next((v) => {
      const { jobCode } = v.$route.params
      v.getJob(atob(jobCode))
      v.$store.dispatch('reposession/updatePrevRoute', from)
    })
  },
  watch: {},
  computed: {
    userData: function () {
      return this.$store.getters['auth/user']
    },
    job: function () {
      const job = this.$store.getters['reposession/getJobObject']()
      if (job !== undefined) {
        if (job.location !== '') {
          this.getLocation(job.location)
        }
      }
      return job
    },
    agents: function () {
      return this.$store.getters['reposession/dashboardGetter']('agents')
    },
    attachmentTypes: function () {
      return this.$store.getters['reposession/dashboardGetter']('attachmentTypes')
    },
    location: function () {
      return this.$store.getters['reposession/dashboardGetter']('location')
    },
    imageDialog: {
      get () {
        return this.$store.getters['reposession/dashboardGetter']('imageDialog').open
      },
      set (value) {
        this.$store.commit('reposession/MUTATE', { state: 'imageDialog', data: { open: value, type: 0 } })
      }
    }
  },
  methods: {
    ...RepoMethods
  }
}
</script>
