<template>
  <div>
    <div style="text-align: center; display: block">
      <Logo class="py-10" />
    </div>
    <div v-if="isLogin">
      <Login/>
    </div>
    <div v-else>
      <Credentials/>
    </div>
    <auth-footer />
  </div>
</template>

<style>
 @import url('../../styles.css');
</style>
<script>
import Login from './login'
import Logo from '../../../../components/Logo'
import Credentials from './Credentials'
import AuthFooter from '../../../../components/AuthFooter'
export default {
  name: 'Landing',
  components: { Login, Logo, Credentials, AuthFooter },
  data: () => ({
    // isLogin: this.$router/
    isLogin: null
  }),
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.isLogin = v.$route.meta.isLogin
    })
  },
  mounted () {}
}
</script>
