const listener = {
  mounted: function () {
    // Global Event Listeners
    Event.$on('login', () => {
      window.location = '/dashboard'
    })

    Event.$on('logout', () => {
      this.$router.push('/auth/login')
    })

    Event.$on('returnCard', (code) => {
      this.$router.replace({ name: 'card', params: { jobCode: btoa(code) } })
    })

    Event.$on('returnMain', () => {
      this.$router.replace({ name: 'main' })
      this.$store.dispatch('reposession/getJobData', this.$store.getters['auth/user'])
    })

    Event.$on('returnHome', () => {
      this.$router.replace({ path: '/' })
    })

    Event.$on('ApiError', (message) => {
      this.$toast.warning(message, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      })
    })

    Event.$on('ApiSuccess', (message) => {
      this.$toast.success(message, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      })
    })

    Event.$on('ApiInfo', (message) => {
      this.$toast.info(message, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      })
    })

    Event.$on('ApiWarning', (message) => {
      this.$toast.warning(message, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      })
    })

    Event.$on('confirm', (data) => {
      this.$swal({
        title: data.message,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: 'yes',
        cancelButtonText: 'no'
      }).then((res) => {
        if (res.isConfirmed) {
          // this.$swal.fire(
          //   data.callbackInfo,
          //   'success'
          // )
          this.$store.dispatch(`reposession/${data.action}`, data.payload)
        }
      })
    })

    Event.$on('comments', (data) => {
      this.$swal({
        title: data.message,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: 'Recall Job Card',
        cancelButtonText: 'close',
        input: 'text',
        inputValidator: function (value) {
          if (value === '') {
            return 'Input Field is Required'
          }
        }
      }).then((res) => {
        if (res.isConfirmed) {
          data.payload.reassigned.comments = res.value
          this.$store.dispatch(`reposession/${data.action}`, data.payload)
        }
      })
    })
  }
}
export default listener
