import moment from 'moment/moment'

class Helper {
  isEmpty (obj) {
    for (var prop in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(prop)) return false
    }
    return true
  }

  prepareFormData (formData) {
    const data = new FormData()
    for (const key in formData) {
      if (formData[key] === null) formData[key] = ''
      data.append(key, formData[key])
    }
    return data
  }

  stringify (object) {
    for (const key in object) {
      if (typeof object[key] === 'object') { object[key] = JSON.stringify(object[key]) }
    }
    return object
  }

  dateFix (date) {
    if (date) {
      if (
        moment(date, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        ) === date
      ) { return date }
      return moment(date).format('Do MMMM YYYY')
    }
    return ''
  }

  changeToHumanReadable (date) {
    if (date) {
      if (
        moment(date, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        ) === date
      ) { return moment(date).format('Do MMMM YYYY') }
    }
    return ''
  }

  prepareDate (date) {
    return date
      ? moment(date)
        .format()
        .substr(0, 10)
      : ''
  }

  sanitizeDate (date) {
    return moment(date)
      .format()
      .substr(0, 10)
  }

  getFileIconColor (fileName) {
    if (!fileName) return '#727071'
    const fileExtension = fileName.split('.').pop()
    switch (fileExtension) {
      case 'docx':
        return '#0276c0'
      case 'pdf':
        return '#e62f34'
      case 'pptx':
        return '#f39301'
      case 'xls':
        return '#82bd8d'
      case 'xlsx':
        return '#82bd8d'
      case 'png':
        return '#a7ce59'
      case 'jpg':
        return '#a7ce59'
      default:
        return '#727071'
    }
  }

  getFileIcon (fileName) {
    if (!fileName) return 'fa fa-file-o'
    const fileExtension = fileName.split('.').pop()
    switch (fileExtension) {
      case 'docx':
        return 'fa fa-file-word'
      case 'pdf':
        return 'fa fa-file-pdf'
      case 'pptx':
        return 'fa fa-file-powerpoint'
      case 'xls':
        return 'fa fa-file-excel'
      case 'xlsx':
        return 'fa fa-file-excel'
      case 'png':
        return 'fa fa-file-image'
      case 'jpg':
        return 'fa fa-file-image'
      default:
        return 'fa fa-file'
    }
  }

  getDateFormat (date) {
    return moment(date).format('Do ddd, MMM YYYY')
  }

  getTimeFormat (time) {
    return moment(time, 'HH:mm:ss').format('hh:mm A')
  }

  detectMimeType (b64) {
    const signatures = {
      JVBERi0: 'application/pdf',
      iVBORw0KGgo: 'image/png',
      '/9j/': 'image/jpg'
    }
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s]
      }
    }
  }

  fileExtension (b64) {
    const signatures = {
      JVBERi0: '.pdf',
      iVBORw0KGgo: '.png',
      '/9j/': '.jpeg'
    }
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s]
      }
    }
  }

  toBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  getStatusColor (status) {
    const colors = {
      Released: 'blue lighten-1',
      'In Progress': 'orange lighten-1',
      Finished: 'green lighten-1',
      Canceled: 'deep-orange lighten-1',
      Submitted: 'teal lighten-1',
      Open: 'amber lighten-1',
      New: 'amber lighten-1',
      Booked: 'cyan lighten-1',
      'Check Out': 'green lighten-1',
      Closed: 'teal lighten-1'
    }
    return colors[status] || 'blue lighten-2'
  }

  shortNumberPipe (number) {
    if (isNaN(number)) return 0 // will only work value is a number
    if (number === null) return 0
    if (number === 0) return 0
    let abs = Math.abs(number)
    const rounder = Math.pow(10, 1)
    const isNegative = number < 0 // will also work for Negative numbers
    let key = ''

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 }
    ]

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value
      reduced = Math.floor(reduced * rounder) / rounder
      if (reduced >= 1) {
        abs = reduced
        key = powers[i].key
        break
      }
    }
    return 'KES ' + (isNegative ? '-' : '') + abs + key
  }
}
export default new Helper()
