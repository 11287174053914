<template>
  <v-container class="my-3">
    <div
      v-if="storage.length === 0"
      style="margin-top: 50%"
      class="d-flex justify-center"
    >
      <div>
        <v-icon size="100">error</v-icon>
        <div class="my-3 ml-3 text-caption">Nothing Here</div>
      </div>
    </div>
    <div
      v-else
      style="margin-bottom: 20%"
      class="mt-4 mx-2"
    >
      <v-card
        class="cardStyle mb-7"
        flat
        style="cursor: pointer"
        ripple
        v-for="(item, i) in storage"
        :key="i"
        @click="$router.replace({ name: 'StorageCard', query: { no: item.no } })"
      >
        <div>
          <v-list-item-avatar
            class="mx-1"
            color="blue lighten-1"
            style="color: #fff"
            >{{ item.agentCode }}</v-list-item-avatar
          >
          <v-chip class="mx-1" label>Doc No: {{ item.no }}</v-chip>
          <v-chip
            style="position: absolute; top: 1em; right: 0.5em"
            :color="getStatusColor(item.status)"
            label
            >{{ item.status }}</v-chip
          >
          <v-list dense>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px"
                  >Customer Name</v-list-item-title
                >
                <v-chip label style="font-weight: bold; font-size: 17px">{{
                  `${item.fullName}`
                }}</v-chip>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px"
                  >Amount</v-list-item-title
                >
                <v-chip label style="font-weight: bold; font-size: 17px">{{
                  item.amount | currency
                }}</v-chip>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px"
                  >Vehicle Registration Number</v-list-item-title
                >
                <v-chip label style="font-weight: bold; font-size: 17px">{{
                  item.vehicleRegNo
                }}</v-chip>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px"
                  >Start Date</v-list-item-title
                >
                <v-chip label style="font-weight: bold; font-size: 17px">{{
                  getDateFormat(item.startDate)
                }}</v-chip>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px"
                  >End Date</v-list-item-title
                >
                <v-chip label style="font-weight: bold; font-size: 17px">{{
                  getDateFormat(item.endDate)
                }}</v-chip>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </v-card>
    </div>
    <v-fab-transition>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
            @click="searchDialog = !searchDialog"
            class="buttons"
          >
            <v-icon> {{ searchDialog ? "mdi-close" : "search" }} </v-icon>
          </v-btn>
        </template>
        <span>Search jobs</span>
      </v-tooltip>
    </v-fab-transition>
    <Search :search="search" @searchInput="updateSearch" />
  </v-container>
</template>
<script>
import helpers from '../../../cetus-base/utilities/helpers/helper.js'
import Search from '../../../cetus-base/components/Search.vue'
export default {
  name: 'StorageList',
  components: { Search },
  data: function () {
    return {
      search: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next((v) => {
      v.$store.dispatch('storage/getStorageItems')
    })
  },
  computed: {
    storage: function () {
      return this.search !== ''
        ? this.$store.getters['storage/storageByStatus'](this.$route.params.status).filter(
          (item, i) =>
            Object.keys(item).filter(
              (key) =>
                item[key]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
            ).length !== 0
        )
        : this.$store.getters['storage/storageByStatus'](this.$route.params.status)
    },
    searchDialog: {
      set (value) {
        this.$store.dispatch('reposession/setSearchDialog', value)
      },
      get () {
        return this.$store.getters['reposession/getSearchDialog']
      }
    }
  },
  methods: {
    getStatusColor: function (status) {
      return helpers.getStatusColor(status)
    },
    getDateFormat: function (date) {
      return helpers.getDateFormat(date)
    },
    updateSearch (val) {
      this.search = val
    }
  }
}
</script>
